<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <!-- POP UP DE PREVIEW IMAGEM-->
      <v-dialog 
        v-model="previewDialog"
        :max-width="getMaxWidth"
      >
        <v-card>
          <v-img contain :src="previewSrc"></v-img>
          <!-- <v-img contain :src="previewSrc" style="background-size: cover"></v-img> -->
          <v-card-actions class="text-center">
            <v-btn
              :style="{
                left: '50%',
                transform: 'translateX(-50%)',
              }"
              color="#009263"
              text
              @click="previewDialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container>
        <v-card class="pa-5">
          <v-container>
            <v-card-title class="justify-center green--text">
              <h2>
                Propor Nova Questão
              </h2>
            </v-card-title>
            <!-- Ajuda -->
            <div class="mb-6">
              <center>
                <v-btn v-if="!show" text @click="show = !show"
                  ><span>Mostrar Ajuda</span
                  ><v-icon color="#009263"> mdi-help-circle </v-icon>
                </v-btn>
                <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
              </center>
              <v-slide-y-transition>
                <v-card
                  v-show="show"
                  class="elevation-6 pa-3"
                  style="border: 2px solid green !important;"
                  color="grey lighten-3"
                >
                  <v-row>
                    <v-col cols="12">
                      <span>
                        1. Esta página tem como propósito guiar o utilizador através do processo de submissão de novas questões para a base de dados.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        2. Esta página está desenhada utilizando um <i><b>Stepper</b></i>, que divide em <b>7</b> passos distintos () os vários campos necessários para a submissão da questão. Caso algum dos campos seja inválido, não será possível avançar para o próximo passo.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        3. <v-btn class="white--text" x-small fab color="#009263">1</v-btn> <b>Escrever Questão</b>: neste passo serão inseridos os <b>meta-dados</b>, a <b>questão</b> em si e a(s) respetiva(s) <b>resposta(s)</b>.
                      </span>
                      <br/>
                      <span class="ml-4">
                        3.1. <b>Meta-Dados</b>
                        <br/>
                        <span class="ml-8">
                          3.1.1 Tanto o <b>Tema</b> como <b>Subtema</b> são caixas de seleção fixas retiradas diretamente da base de dados. Serão considerados inválidos valores que não coincidam com a lista dos selecionáveis.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.1.2 O <b>Nível</b> é um número inteiro cujo valor, no mínimo, é <b>1</b> e no máximo <b>4</b>. Quaisquer valores que não respeitem este intervalo ou que não sejam um número serão considerados inválidos.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.1.3 O <b>Ano</b> é semelhante ao anterior porém o intervalo é de <b>1</b> a <b>9</b>.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.1.4 Caso seja uma questão de exame, deverá clicar no quadrado com a descrição "É questão de exame?" e escrever na caixa de seleção (<b>Exame</b>) ao lado o nome do exame. Caso já exista na base de dados, será sugerido através da auto-compleção. Este conjunto de campos só é considerado inválido se o quadrado estiver selecionado e com nada escrito no <b>Exame</b>, simultaneamente.
                        </span>
                      </span>
                      <br/>
                      <span class="ml-4">
                        3.2. <b>Questão</b>
                        <br/>
                        <span class="ml-8">
                          3.2.1 O <b>Tipo</b> define se a questão é de <b>Escolha Múltipla</b>, <b>Resposta Aberta</b>, <b>Verdadeiro ou Falso</b> ou <b>Simetria</b>.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.2.2 O <b>Subtipo</b> depende do <b>Tipo</b> selecionado e é utilizado de modo a modificar a secção das <b>Respostas</b> (3.3) consoante a necessidade.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.2.3 A <b>Questão</b>, por fim, é o campo onde é inserido o enunciado da questão a submeter e só é considerada inválida se estiver vazia. Existem vários modificadores disponíveis para estilizar o texto da mesma:
                          <ul class="ml-12">
                            <li> <b>&lt;sub&gt;Potência&lt;sub&gt;</b>: serve para exprimir potências. Por exemplo, 2<sup>3</sup> seria escrito 2&lt;sub&gt;3&lt;sub&gt; </li>
                            <li> <b>&lt;raiz&gt;Raíz&lt;raiz&gt;</b>: serve para exprimir raízes quadradas. Por exemplo, <span class="raiz">&radic;<span class="raiz-content">2</span></span> seria escrito &lt;raiz&gt;2&lt;raiz&gt;</li> 
                            <li> <b>mQQ</b>: serve para exprimir a relação (&gt;) ("maior que"). Por exemplo, 3 &gt; 2 seria escrito 3 mQQ 2 </li>
                          </ul>
                        </span>
                      </span>
                      <span class="ml-4">
                        3.3. <b>Respostas</b>
                        <br/>
                        <span class="ml-8">
                          3.3.1 Uma resposta só é considerada inválida se estiver vazia, para qualquer um dos casos que se seguem.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.3.2 Assim como a questão, é possível estilizar o texto que compõe cada uma das respostas:
                          <ul class="ml-12">
                            <li> <b>&lt;sub&gt;Potência&lt;sub&gt;</b>: serve para exprimir potências. Por exemplo, 2<sup>3</sup> seria escrito 2&lt;sub&gt;3&lt;sub&gt; </li>
                            <li> <b>&lt;raiz&gt;Raíz&lt;raiz&gt;</b>: serve para exprimir raízes quadradas. Por exemplo, <span class="raiz">&radic;<span class="raiz-content">2</span></span> seria escrito &lt;raiz&gt;2&lt;raiz&gt;</li> 
                            <li> <b>+xpto e -xpto</b>: serve para introduzir o símbolo +&infin; e -&infin;, respetivamente </li>
                          </ul>
                        </span>
                        <span class="ml-8">
                          3.3.3 Nas respostas do tipo <b>Escolha Múltipla</b>, a primeira resposta deverá ser a resposta correta e as restantes alternativas serem incorretas. Caso sejam do subtipo <b>Respostas de Texto</b>, têm no mínimo <b>3</b> respostas e no máximo <b>6</b>. Este número pode ser manipulado através dos botões <v-btn small class="white--text mx-1" rounded color="#009263"><v-icon>mdi-plus-circle</v-icon></v-btn> e <v-btn small class="white--text mx-1" rounded color="#009263"><v-icon>mdi-minus-circle</v-icon></v-btn> que incrementam e decrementam o número de respostas, respetivamente. Caso sejam do subtipo <b>Respostas de Imagens</b>, é necessário inserir uma imagem do computador no formato <b>PNG</b> em cada uma delas. 
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.3.4 Nas respostas do tipo <b>Verdadeiro ou Falso</b>, só existem duas respostas e, tal como na anterior, a primeira resposta deverá ser a correta e a segunda deverá ser a incorreta.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.3.5 Nas respostas do tipo <b>Resposta Aberta</b>, caso o seu subtipo seja <b>1 Resposta Correta</b>, <b>2 Respostas Corretas</b> ou <b>Várias Respostas Corretas</b>, todas as respostas são consideradas corretas. Caso seja do tipo <b>Fração Redutível</b>, é necessário introduzir o numerador e o denominador da fração, pelo que a resposta final poderá ser qualquer fração <b>equivalente</b> à inserida. Por outro lado, caso seja <b>Fração Irredutível</b>, a resposta correta é apenas a fração resultante do numerador e denominador inseridos. Por fim, caso seja <b>Utilizar Transferidor</b>, a resposta correta (em graus) está no intervalo dado pelos dois valores que atuam como limites inferior e superior. Em todos os subtipos exceto este último existe também um campo extra com a <b>unidade</b> a ser utilizada e, tal como a questão, pode ter texto estilizado com <b>&lt;sub&gt;Potência&lt;sub&gt;</b> (ver acima), caso necessário.
                        </span>
                        <br/>
                        <span class="ml-8">
                          3.3.6 Nas respostas do tipo <b>Simetria</b>, é apresentada uma grelha 5x6 caso seja um eixo na vertical ou 6x5 caso seja na horizontal. Ao clicar nos items da grelha, os pontos a <span style="color: #ff6600"><b>laranja</b></span> determinam a simetria que se pretende realizar. Por outro lado, os pontos a <span style="color: #4CC74D"><b>verde</b></span><b></b> determinam a solução da simetria. O lado que contém a simetria a resolver é escolhido no <b>Subtipo</b>, assim como o eixo.
                        </span>
                        <br/>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        4. <v-btn class="white--text" x-small fab color="#009263">2</v-btn> <b>Adicionar Imagem</b>: neste passo será realizada a decisão de inserir ou não uma imagem representativa para a questão. Caso seja clicado o quadrado de confirmação, é possível escolher entre vários <i>templates</i> (modelos) que serão utilizados no passo seguinte. Caso contrário, passará diretamente para o passo <b>4</b>.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        5. <v-btn class="white--text" x-small fab color="#009263">3</v-btn> <b>Editar Imagem</b>: neste passo será realizada a edição da imagem através do software <b>ToastUI</b>. Caso tenha sido escolhido um <i>template</i>, este será a base de edição da mesma. Se foi selecionado <b>Em Branco</b> no passo anterior, será necessário inserir uma imagem do computador utilizando o botão <b>Carregar</b> presente no canto superior direito do editor e poderá proceder à edição da mesma. Após ter a imagem pronta, é possível inserir formas, texto, imagens, entre outros e, quando terminada a edição, é possível passar ao passo seguinte. A imagem só é considerada inválida se não existir e o quadrado de confirmação estiver clicado, simultaneamente.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        6. <v-btn class="white--text" x-small fab color="#009263">4</v-btn> <b>Adicionar Resolução</b>: passo semelhante ao passo <b>2</b> e serve para inserir uma imagem com a resolução da questão, porém com novos <i>templates</i> e, caso não seja clicado o quadrado de confirmação, passa diretamente ao passo <b>6</b>.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        7. <v-btn class="white--text" x-small fab color="#009263">5</v-btn> <b>Editar Resolução</b>: passo semelhante ao passo <b>3</b> mas com o <i>template</i> escolhido em <b>4</b> e está sujeito às mesmas regras.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        8. <v-btn class="white--text" x-small fab color="#009263">6</v-btn> <b>Pré-Visualizar</b>: neste passo é possível pré-visualizar como a questão irá aparecer na plataforma caso seja aprovada. Caso seja necessário, é possível retroceder para passos anteriores e alterar os vários campos.
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <span>
                        9. <v-btn class="white--text" x-small fab color="#009263">7</v-btn> <b>Confirmar</b>: último passo do processo e dá mais uma chance ao utilizador de retroceder para efetuar alterações ou submeter a questão para as <b style="color:#009263">Questões Propostas</b>, onde ficará até ser aceite, editada ou recusada por outro utilizador.
                      </span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-slide-y-transition>
            </div>
            <!-- START -->
            <div v-if="loadingPage">
                <v-container>
                  <center>
                    <v-img
                      :src="require('@/assets/loading.gif')"
                      width="150px"
                      heigth="150px"
                    >
                    </v-img>
                  </center>
                </v-container>
            </div>
            <v-stepper v-else v-model='e1' class="elevation-0">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                  color="#009263"
                  editable
                >
                  Escrever Questão
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 2"
                  :editable="e1 > 1"
                  step="2"
                  color="#009263"
                >
                  Adicionar Imagem
                </v-stepper-step>

                <v-divider></v-divider>
                
                 <v-stepper-step
                  :complete="e1 > 3"
                  :editable="e1 > 2 && addImagemToggle"
                  step="3"
                  color="#009263"
                >
                  Editar Imagem
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 4"
                  :editable="e1 > 3"
                  step="4"
                  color="#009263"
                >
                  Adicionar Resolução
                </v-stepper-step>

                <v-divider></v-divider>
                
                 <v-stepper-step
                  :complete="e1 > 5"
                  :editable="e1 > 4 && addResolucaoToggle"
                  step="5"
                  color="#009263"
                >
                  Editar Resolução
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 6"
                  step="6"
                  color="#009263"
                >
                  Pré-Visualizar
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="7"
                  color="#009263"
                >
                  Confirmar
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step='1'>
                  <v-card-title class="green--text">
                    <h2>
                      Meta-Dados
                    </h2>
                  </v-card-title>
                  <!-- Temas -->
                  <v-row>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          color="#009263"
                          item-color="green"
                          outlined
                          clearable
                          flat
                          v-model="temaSelected"
                          @click:clear="wipeSubtema"
                          @input="temaSelected = $event !== null ? $event : ``"
                          :items="temasFiltro"
                          label="Tema"
                          hide-details
                          @change="changeSubtemas"
                          prepend-icon="mdi-format-list-bulleted"
                          :rules="textRules"
                        ></v-select>
                      </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-select
                        color="#009263"
                        item-color="green"
                        hide-details
                        clearable
                        no-data-text="Selecione um tema primeiro."
                        v-model="subtemaSelected"
                        @input="subtemaSelected = $event !== null ? $event : ``"
                        outlined
                        flat
                        :items="subtemas"
                        label="Subtema"
                        :rules="textRules"
                        prepend-icon="mdi-format-list-text">
                      ></v-select>
                    </v-col>
                  </v-row>
                  <!-- SELECIONADOR ANO/NIVEL -->
                  <v-row>
                    <v-col cols="4" sm="4" md="4" lg="2" xl="2">
                        <v-text-field
                          color="#009263"
                          outlined
                          hide-details
                          v-model="nivelSel"
                          type="number"
                          label="Nível"
                          min="1"
                          max="4"
                          :rules="nivelRules"
                          prepend-icon="mdi-head-cog-outline"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" lg="2" xl="2">
                      <v-text-field
                          color="#009263"
                          outlined
                          v-model="anoSel"
                          type="number"
                          label="Ano"
                          hide-details
                          min="1"
                          max="9"
                          :rules="anoRules"
                          prepend-icon="mdi-ruler-square-compass"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- EXAME TOGGLE -->
                  <v-row>
                    <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                        <v-checkbox
                          align="center"
                          v-model="exameToggle"
                          color="#009263"
                          hide-details
                          prepend-icon="mdi-set-square"
                          mandatory
                          label="É questão de exame?"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                        <v-combobox
                          color="#009263"
                          item-color="green"
                          outlined
                          hide-details
                          clearable
                          :rules="textRules"
                          v-model="exameValue"
                          :visible="exameToggle===false"
                          :disabled="exameToggle===false"
                          :items="exames"
                          @input="exameValue = $event !== null ? $event : ``"
                          flat
                          label="Exame"
                          prepend-icon="mdi-school-outline"
                        ></v-combobox>
                      </v-col>
                  </v-row>
                  <!--Tipo-->
                  <v-card-title class="green--text">
                    <h2>
                      Questão
                    </h2>
                  </v-card-title>
                  <v-row>
                    <v-col cols="5" sm="5" md="4" lg="3" xl="3">
                      <v-select
                        color="#009263"
                        item-color="green"
                        outlined
                        flat
                        v-model="tipoSel"
                        @input="tipoSel = $event !== null ? $event : ``"
                        @change="changeTipo()"
                        :items="tipos"
                        label="Tipo"
                        hide-details
                        prepend-icon="mdi-clipboard-list-outline"
                      ></v-select>
                    </v-col>
                    <v-col cols="7" sm="7" md="6" lg="4" xl="4">
                      <v-select
                        color="#009263"
                        item-color="green"
                        outlined
                        flat
                        :disabled="tipoSel!=='Resposta Aberta' && tipoSel !== 'Escolha Múltipla' && tipoSel !== 'Simetria' "
                        v-model="subtipoSel"
                        @input="subtipoSel = $event !== null ? $event : ``"
                        @change="changeSubtipo()"
                        :items="getCorrectTipo"
                        label="Subtipo"
                        hide-details
                        :rules="textRules"
                        prepend-icon="mdi-flag-variant-outline"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <!-- <v-row >
                    <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                        <v-checkbox
                          align="center"
                          v-model="picToggle"
                          color="#009263"
                          hide-details
                          prepend-icon="mdi-paperclip"
                          mandatory
                          label="Adicionar imagem?"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" lg="5" xl="5">
                      <v-file-input
                        color="#009263"
                        rows="1"
                        hide-details
                        outlined
                        clearable
                        label="Imagem"
                        @change="inputImagem($event)"
                        prepend-icon="mdi-camera"
                        v-model="imgSel"
                        accept='image/png'
                        :rules="imgRules"
                        :disabled="!picToggle"
                        append-icon="mdi-eye"
                        @click:append="clickAppend"
                        >
                      </v-file-input>
                    </v-col>
                  </v-row> -->
                  <!-- Questao -->
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <v-textarea
                        color="#009263"
                        rows="1"
                        auto-grow
                        hide-details
                        outlined
                        clearable
                        type="text"
                        label="Questão"
                        prepend-icon="mdi-clipboard-edit-outline"
                        v-model="questao"
                        @input="questao = $event !== null ? $event : ``"
                        :rules="textRules">
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-card-title class="green--text">
                      <h2>
                        Respostas
                      </h2>
                  </v-card-title>
                  
                  <!-- Respostas -->
                  <div v-if="this.subtipoSel==='Respostas de Imagens'">
                    <v-row align="center" v-for="index in numResps" :key="index">
                      <v-col cols="5" sm="5" md="5" lg="5" xl="5">
                      <v-file-input
                        color="#009263"
                        rows="1"
                        hide-details
                        outlined
                        clearable
                        :label="getLabel(index)"
                        @change="inputRespostaImagens($event, index)"
                        prepend-icon="mdi-camera"
                        v-model="respsImagem[index-1].img"
                        accept='image/png'
                        :rules="imgRules"
                        append-icon="mdi-eye"
                        @click:append="clickAppendImagens(index)"
                        >
                      </v-file-input>
                      </v-col>
                    </v-row>
                  </div>
                  
                  <div v-else-if="this.tipoSel==='Simetria'">
                    <clickable-grid @hook:mounted="mountedClickable" ref="clickable_grid" :type="orientationType" :axis='axisType'></clickable-grid>
                  </div>

                  <div v-else >
                    <v-row align="center" v-for="index in numResps" :key="index">
                    <v-col cols="5" sm="5" md="5" lg="5" xl="5">
                      <v-text-field
                        color="#009263"
                        rows="1"
                        hide-details
                        outlined
                        clearable
                        type="text"
                        :label="getLabel(index)"
                        @input="inputResposta($event, index)"
                        prepend-icon="mdi-pencil"
                        v-model="resps[index-1].resp"
                        :rules="textRules">
                      </v-text-field>
                    </v-col>
                    <!-- Botões -->
                    <div v-if="index == numResps">
                      <v-btn v-if="isEM"
                        class="white--text mx-1"
                        :disabled='isFull'
                        rounded
                        @click="incrementarNResps"
                        large
                        color="#009263"
                      > 
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </div>
                    <div v-if="isEM" >
                      <v-btn v-if="index == numResps" 
                        class="white--text mx-1"
                        rounded
                        :disabled='isEmpty'
                        @click="decrementarNresps"
                        large
                        color="#009263"
                        align="center"
                      > 
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                    </div>
                    </v-row>
                  </div>
                  
                  <v-row>
                    <v-col cols="2" sm="2" md="2" lg="2" xl="2">
                      <v-text-field v-if="hasUnidade"
                        color="#009263"
                        rows="1"
                        hide-details
                        outlined
                        clearable
                        type="text"
                        label="Unidade"
                        prepend-icon="mdi-ruler"
                        v-model="unidade"
                        @input="unidade = $event !== null ? $event : ``">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Submeter -->
                  <v-row>
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-tooltip v-if="hasError" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on">
                            <v-btn 
                            large 
                            class="white--text"
                            color="#009263"  
                            disabled
                            v-bind="attrs"
                            >Continuar</v-btn>
                          </div>
                        </template>
                        <span>Corrija os erros sinalizados a vermelho para continuar.</span>
                      </v-tooltip>
                      <v-btn v-else
                        large
                        class="white--text"
                        color="#009263"
                        @click="e1=2; criarJSONPergunta();"
                        >Continuar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='2'>
                  <v-checkbox
                          align="center"
                          color="#009263"
                          v-model="addImagemToggle"
                          hide-details
                          prepend-icon="mdi-file-image-outline"
                          mandatory
                          label="Adicionar imagem?"
                  ></v-checkbox>
                  <div v-if="addImagemToggle">
                    <v-container class="ml-6 text-left my-2">
                    <p>Selecione um dos seguintes <i>templates</i>.</p>
                    <p>Caso pretenda utilizar uma imagem do seu computador, selecione a opção <b>"Em Branco"</b> e, de seguida, selecione <b>Carregar</b>.</p>
                  </v-container>
                  <v-container class="pa-1">
                  <v-item-group
                    v-model="selTemplateImagem"
                    @change="changeTemplateImagem"
                  >
                    <v-row>
                      <v-col
                        v-for="(item, i) in templates_imagem"
                        :key="i"
                        cols="12"
                        sm="4" xs="3" md="3" lg="2" xl="2"
                        class="ml-8"
                      >
                        <v-item v-slot="{ active, toggle }">
                          <div>
                            <v-btn
                              @click="toggle"
                              class="ml-n2"
                              color="#009263"
                              icon
                              dark
                            >
                              <v-icon color="#009263">
                                {{ active ? 'mdi-checkbox-marked' : 'mdi-square-outline' }}
                              </v-icon>
                            </v-btn>
                            {{ item.label }}
                            <v-card>
                            
                          <v-img
                            :src="item.path"
                            contain
                            class="text-right pa-2"
                          >
                            
                          </v-img>
                          </v-card>
                          </div>
                        </v-item>
                      </v-col>
                    </v-row>
                   </v-item-group>
                  </v-container>
                  </div>
                  <v-row>
                    <v-col class="mt-6 ml-8" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="addImagemToggle ? e1=3 : e1=4"
                        >Continuar</v-btn
                      >
                      <v-btn 
                        text
                        @click="e1=1"
                        >Voltar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='3'>
                  <Resolucao @uploadPic="userImagemUpload" ref="imagemRef" :key="reloadEditor2Key" :template="templatePropImagem"/>
                  <v-row class="mt-4 ml-8">
                     <v-col cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-tooltip v-if="hasNoImagem" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on">
                            <v-btn 
                            large 
                            class="white--text"
                            color="#009263"  
                            disabled
                            v-bind="attrs"
                            >Pré-Visualizar</v-btn>
                            <v-btn 
                              text
                              @click="e1=2"
                              >Voltar</v-btn
                            >
                          </div>
                        </template>
                        <span>Carregue uma imagem para continuar.</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn 
                          large
                          class="white--text"
                          color="#009263"
                          @click="beforePreviewImagem"
                          >Continuar</v-btn
                        >
                        <v-btn 
                          text
                          @click="e1=2"
                          >Voltar</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='4'>
                  <v-checkbox
                          align="center"
                          color="#009263"
                          v-model="addResolucaoToggle"
                          hide-details
                          prepend-icon="mdi-file-image-outline"
                          mandatory
                          label="Adicionar resolução?"
                  ></v-checkbox>
                  <div v-if="addResolucaoToggle">
                    <v-container class="ml-6 text-left my-2">
                    <p>Selecione um dos seguintes <i>templates</i>.</p>
                    <p>Caso pretenda utilizar uma imagem do seu computador, selecione a opção <b>"Em Branco"</b> e, de seguida, selecione <b>Carregar</b>.</p>
                  </v-container>
                  <v-container class="pa-1">
                  <v-item-group
                    v-model="selTemplate"
                    @change="changeTemplate"
                  >
                    <v-row>
                      <v-col
                        v-for="(item, i) in templates_resolucao"
                        :key="i"
                        cols="12"
                        sm="4" xs="3" md="3" lg="2" xl="2"
                        class="ml-8"
                      >
                        <v-item v-slot="{ active, toggle }">
                          <div>
                            <v-btn
                              @click="toggle"
                              class="ml-n2"
                              color="#009263"
                              icon
                              dark
                            >
                              <v-icon color="#009263">
                                {{ active ? 'mdi-checkbox-marked' : 'mdi-square-outline' }}
                              </v-icon>
                            </v-btn>
                            {{ item.label }}
                            <v-card>
                            
                          <v-img
                            :src="item.path"
                            contain
                            class="text-right pa-2"
                          >
                            
                          </v-img>
                          </v-card>
                          </div>
                          
                        </v-item>
                      </v-col>
                    </v-row>
                   </v-item-group>
                  </v-container>
                  </div>
                  <v-row>
                    <v-col class="mt-6 ml-8" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="resetPreview"
                        >Continuar</v-btn
                      >
                      <v-btn 
                        text
                        @click="addImagemToggle ? e1=3 : e1=2"
                        >Voltar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='5'>
                  <Resolucao @uploadPic="userResolucaoUpload" ref="resolucaoRef" :key="reloadEditorKey" :template="templateProp"/>
                  <v-row class="mt-4 ml-8">
                     <v-col cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-tooltip v-if="hasNoResolucao" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on">
                            <v-btn 
                            large 
                            class="white--text"
                            color="#009263"  
                            disabled
                            v-bind="attrs"
                            >Pré-Visualizar</v-btn>
                            <v-btn 
                              text
                              @click="e1=4"
                              >Voltar</v-btn
                            >
                          </div>
                        </template>
                        <span>Carregue uma imagem para continuar.</span>
                      </v-tooltip>
                      <div v-else>
                        <v-btn 
                          large
                          class="white--text"
                          color="#009263"
                          @click="beforePreview"
                          >Pré-Visualizar</v-btn
                        >
                        <v-btn 
                          text
                          @click="e1=4"
                          >Voltar</v-btn
                        >
                      </div>
                      
                      
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='6'>
                  <Preview :key="reloadPreview" :pergunta="perguntaPreview" :image_urls="imgPreviewUrls"/>
                  <!-- Botoes -->
                  <v-row class="mt-2 mr-2">
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="e1=7"
                        >Confirmar</v-btn
                      >
                      <v-btn 
                        text
                        @click="addResolucaoToggle ? e1 = 5 : e1 = 4"
                        >Voltar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step='7'>
                  <v-card-title class="ml-3 green--text">
                    <h2>
                      Confirmação
                    </h2>
                  </v-card-title>
                  <!-- Temas -->
                  <v-container class="ml-5 text-left my-2">
                    <p>A questão está pronta para ser submetida para avaliação.</p>
                    <p>Clique no botão de confirmação para concluir o processo.</p>
                    <p>Após a confirmação, será redirecionado para a página de submissões.</p>
                    <p>Caso pretenda alterar a submissão posteriormente é possível fazê-lo.</p>
                  </v-container>
                  <v-row>
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="submeter"
                        >Submeter</v-btn
                      >
                      <v-btn 
                        text
                        @click="e1=6"
                        >Voltar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <div id="scroll-here"></div>
  </v-app>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const Swal = require("sweetalert2");

import Preview from "../components/Preview.vue";
import Resolucao from "../components/Resolucao.vue";
import ClickableGrid from "../components/ClickableGrid.vue";

export default {
  name: "FormPergunta",
  components:{
    Preview,
    Resolucao,
    ClickableGrid
  },
  data() {
    return {
      show: false,
      loadingPage: true,
      tipos: ['Escolha Múltipla', 'Resposta Aberta', 'Verdadeiro ou Falso', 'Simetria'],
      tipoSel: 'Escolha Múltipla',
      subtipos: ["1 Resposta Correta", "2 Respostas Corretas", "Várias Respostas Corretas", "Fração Redutível", "Fração Irredutível", "Utilizar Transferidor"],
      subtiposEM: ["Respostas de Texto", "Respostas de Imagens"],
      subtipoSel: "Respostas de Texto",
      subtiposSim: ["Eixo Vertical - Esquerda", "Eixo Vertical - Direita", "Eixo Horizontal - Cima", "Eixo Horizontal - Baixo"],
      temas: [],
      temasFiltro: [],
      subtemas: [],
      catalogoTemas: {},
      temaSelected: '',
      subtemaSelected: '',
      exameToggle: false,
      exames: [],
      exameValue: '',
      anoSel: 1,
      nivelSel: 1,
      anoRules: [(v) => v && v > 0 && v < 10],
      nivelRules: [(v) => v && v > 0 && v < 5],
      textRules: [(v) => !!v],
      imgRules: [
        v => !!v || 'File is required',
        v => (v && v.type.split('/')[0] === 'image') || 'Image required',
      ],
      picToggle: false,
      imgSel: null,
      pergunta: {},
      perguntaPreview: {},
      imgPreviewUrls: {'1': null, '2': null, '3': null, '4': null, 'main': null, 'resol': null},
      questao: '',
      resps: [ {"resp": ''}, {"resp": ''}, {"resp": ''}, {"resp": ''}, {"resp": ''}, {"resp": ''} ], 
      respsImagem: [ {"img": null}, {"img": null}, {"img": null}, {"img": null} ],
      numResps: 3,
      unidade: '',
      auxiliar: 0,
      e1: 1,

      templates_resolucao:[
        {
          name: 'template0.png',
          path: '/templates/resolucao/template0.png',
          label: 'Em Branco'
        },
        {
          name: 'template1.png',
          path: '/templates/resolucao/template1.png',
          label: 'Horizontal 1'
        },
        {
          name: 'template2.png',
          path: '/templates/resolucao/template2.png',
          label: 'Vertical 1'
        },
        {
          name: 'template3.png',
          path: '/templates/resolucao/template3.png',
          label: 'Vertical 2'
        },
        {
          name: 'template4.png',
          path: '/templates/resolucao/template4.png',
          label: 'Vertical 3'
        },
      ],

      templates_imagem:[
        {
          name: 'i_template0.png',
          path: '/templates/imagem/i_template0.png',
          label: 'Em Branco'
        },
        {
          name: 'i_template1.png',
          path: '/templates/imagem/i_template1.png',
          label: 'Pensar'
        },
        {
          name: 'i_template2.png',
          path: '/templates/imagem/i_template2.png',
          label: 'Gráfico'
        },
        {
          name: 'i_template3.png',
          path: '/templates/imagem/i_template3.png',
          label: 'Grelha 1'
        },
        {
          name: 'i_template4.png',
          path: '/templates/imagem/i_template4.png',
          label: 'Grelha 2'
        },
      ],

      isMounted: false,
      templateProp: {},
      templatePropImagem: {},
      selTemplate: 0,
      selTemplateImagem: 0,
      addResolucaoToggle: false,
      addImagemToggle: false,
      reloadEditorKey: 0,
      reloadEditor2Key: 0,
      userResUpload: false,
      userImgUpload: false,
      resImgName: '',
      imgImgName: '',

      previewDialog: false,
      resolCard: '900px',
      previewSrc: '',

      //grid
      orientationType: 'v',
      axisType: 'e',
      clickableMounted: false,

      reloadPreview: 0,
    };
  },

  methods: {
    getTema(tema){
      return this.temas[tema].tema 
    },

    getSubtema(tema,subtema){
      return this.temas[tema].subtemas[subtema]
    },

    getTipo(tipo){
      return tipo === 1 
      ? this.tipos[1]
      : tipo === 3 
      ? this.tipos[2]
      : tipo === 7 || tipo === 33
      ? this.tipos[3]
      : this.tipos[0]
    },

    changeSubtemas(){
      this.subtemaSelected = ''
      let tema = this.catalogoTemas[this.temaSelected]
      if(this.temaSelected != null && this.temaSelected!= '') this.subtemas = Object.entries(this.temas[tema].subtemas).map(e => e[1])
      else this.subtemas = []
    },

    getSubtemaNr(tema){
      let subtemas = this.temas[tema].subtemas 
      if(this.temaSelected != '' && this.subtemaSelected != ''&& this.temaSelected != null && this.subtemaSelected != null) {
          for (var [key, value] of Object.entries(subtemas)) {
              if(this.subtemaSelected == value) return key
          }
      }
      return ''
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
      this.temasFiltro.sort((a, b) => a.localeCompare(b))
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    async getExames(){
      const data = await axios.get(host + 'perguntas/exames')
      this.exames = data.data
    },

    changeTemplate(){
      this.templateProp = this.templates_resolucao[this.selTemplate]
      this.reloadEditorKey++
      //console.log("SELTEMPLATE ",this.templateProp)
    },

    changeTemplateImagem(){
      this.templatePropImagem = this.templates_imagem[this.selTemplateImagem]
      this.reloadEditor2Key++
      //console.log("SELTEMPLATE IMAGEM",this.templateProp)
    },

    dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: 'image/png'});
    },

    userResolucaoUpload(){
      let name = this.$refs.resolucaoRef.$refs.tuiImageEditor.invoke('getImageName')
      if(name !== 'FilterImage'){
        this.resImgName = `${name.substr(0, name.lastIndexOf('.')) || name}_02.png`;
      }
      //console.log("botao clicked", name)
      this.userResUpload = name !== ''
    },

    userImagemUpload(){
      let name = this.$refs.imagemRef.$refs.tuiImageEditor.invoke('getImageName')
      if(name !== 'FilterImage'){
        this.imgImgName = `${name.substr(0, name.lastIndexOf('.')) || name}_01.png`;
        //console.log("imgImgName", this.imgImgName)
      }
      //console.log("botao clicked", name)
      this.userImgUpload = name !== ''
    },

    beforePreview(){
      this.e1 = 6
      this.imgPreviewUrls.resol = this.$refs.resolucaoRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png")
      //console.log(this.imgPreviewUrls)
    },

    beforePreviewImagem(){
      this.e1 = 4
      this.imgPreviewUrls.main = this.$refs.imagemRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png")
      //console.log(this.imgPreviewUrls)
    },

    resetPreview(){
      if(!this.addImagemToggle){
        this.imgPreviewUrls.main = null
      }

      if(!this.addResolucaoToggle){
        this.imgPreviewUrls.resol = null
      }

      this.reloadPreview++
      this.addResolucaoToggle ? this.e1=5 : this.e1=6
    },

    mountedClickable(){
      this.clickableMounted = true
    },

    imgSize(url, callback) {
      var img = new Image();
      img.onload = function() {
        callback(img.width);
      };
      img.src = url;
    },

    clickAppend(){
      if(this.imgPreviewUrls.main && this.imgPreviewUrls.main !=  ''){
        this.previewSrc = this.imgPreviewUrls.main
        this.imgSize(this.imgPreviewUrls.main, (w) => {
           this.resolCard = `${w}px`;
           this.previewDialog = true
        });
      }
    },

    clickAppendImagens(index){
      if(this.imgPreviewUrls[index] && this.imgPreviewUrls[index] !=  ''){
        this.previewSrc = this.imgPreviewUrls[index]
        this.imgSize(this.imgPreviewUrls[index], (w) => {
           this.resolCard = `${w}px`;
           this.previewDialog = true
        });
      }
    },

    changeTipo(){
      this.clickableMounted = false
      this.numResps = this.tipoSel === this.tipos[1]  || this.tipoSel === this.tipos[3]
                    ? 1
                    : this.tipoSel === this.tipos[2]
                    ? 2 
                    : 3
      
      switch(this.tipoSel){
        case this.tipos[0]:
          this.subtipoSel = this.subtiposEM[0]
          this.auxiliar = 0
          break 
        case this.tipos[1]:
          this.subtipoSel= this.subtipos[0]
          this.auxiliar = 1
          break
        case this.tipos[3]:
          this.subtipoSel= this.subtiposSim[0]
          this.auxiliar = 0
          break
        default: 
          this.auxiliar = 0
          this.subtipoSel = ''
          break
          
      }
      //console.log("Auxiliar: ", this.auxiliar)
      //console.log("Nº respostas:", this.numResps)
    },

    changeSubtipo(){
      if (this.tipoSel === this.tipos[0]){
        switch(this.subtipoSel){
          case this.subtiposEM[0]:
            this.auxiliar = 0
            this.numResps = 3
            break
          case this.subtiposEM[1]:
            this.auxiliar = 0
            this.numResps = 4
            break
        }
      }
      else if (this.tipoSel === this.tipos[3]){
        switch(this.subtipoSel){
          case this.subtiposSim[0]:
            this.orientationType = 'v'
            this.axisType = 'e'
            break 
          case this.subtiposSim[1]:
            this.orientationType = 'v'
            this.axisType = 'd'
            break 
          case this.subtiposSim[2]:
            this.orientationType = 'h'
            this.axisType = 'c'
            break 
          case this.subtiposSim[3]:
            this.orientationType = 'h'
            this.axisType = 'b'
            break 
        }
        this.auxiliar = 0
        this.numResps = 1
      }
      else{
        this.numResps = 2
        switch(this.subtipoSel){
          case this.subtipos[0]:
            this.auxiliar = 1
            this.numResps = 1
            break
          case this.subtipos[1]:
            this.auxiliar = 1000
            break
          case this.subtipos[2]:
            this.auxiliar = 0
            break
          case this.subtipos[3]:
            this.auxiliar = 22
            break
          case this.subtipos[4]:
            this.auxiliar = 2
            break
          case this.subtipos[5]:
            this.auxiliar = 10
            break
          default:
            this.auxiliar = 0
            break
        }
      }
      
      //console.log("Auxiliar: ", this.auxiliar)
    },

    printResposta(index){
      console.log(this.resps[index-1].resp)
      console.log(this.resps)
    },

    incrementarNResps(){
      this.numResps++
      let element = document.getElementById("scroll-here");
      this.$vuetify.goTo(element, {
                    duration: 200,
                    offset: 0,
                    easing: "easeInOutCubic"
                });
      //element.scrollIntoView({behavior: "smooth"});
      //window.scrollTo(0,document.querySelector(".v-main__wrap").scrollHeight);
      //console.log("Nº respostas ++: " + this.numResps)
    },

    decrementarNresps(){
      this.numResps--
      this.resps[this.numResps].resp = ''
      //console.log("Nº respostas --: " + this.numResps)
    },

    inputResposta(event, index){
      this.resps[index - 1].resp = event !== null ? event : ``
      //console.log("Resposta " + index + ": " + this.resps[index - 1].resp)
    },

    createImage(file, index){
      const reader = new FileReader();
      
      reader.onload = (e) => {
        this.imgPreviewUrls[index] = e.target.result;
        //console.log(this.imgPreviewUrls)
      };

      reader.readAsDataURL(file);
      
    },

    inputRespostaImagens(event, index){
      if (!event) {
        //console.log("delete?")
        this.imgPreviewUrls[index] = null;
        //console.log(this.imgPreviewUrls)
        return
      }
      this.createImage(event, index);
      //console.log("Resposta (Imagem) " + index + ": ", this.respsImagem[index - 1].img)
      //this.respsImagem[index - 1].img = event !== null ? event : ``
      //let img = this.respsImagem[index - 1].img
      //if (img && img.type.split('/')[0] !== 'image') this.respsImagem[index - 1].img = null
    },

    inputImagem(event){
      if (!event) {
        //console.log("delete?")
        this.imgPreviewUrls.main = null;
        //console.log(this.imgPreviewUrls)
        return
      }
      this.createImage(event, 'main');
      //console.log("Resposta (Imagem) " + 'main' + ": ", this.imgSel)
      //this.respsImagem[index - 1].img = event !== null ? event : ``
      //let img = this.respsImagem[index - 1].img
      //if (img && img.type.split('/')[0] !== 'image') this.respsImagem[index - 1].img = null
    },
    
    getLabel(index){
      switch(this.tipoSel){
        case this.tipos[0]:
          return index === 1 ? 'Resposta' : 'Alternativa Incorreta ' + (index - 1) 
        case this.tipos[1]:
          switch(this.subtipoSel){
            case this.subtipos[0]:
              return 'Resposta'
            case this.subtipos[1]:
              return index === 1 ? 'Resposta' : 'Alternativa'
            case this.subtipos[2]:
              return index === 1 ? 'Resposta' : 'Alternativa ' + (index - 1)
            case this.subtipos[3]:
              return index === 1 ? 'Numerador' : 'Denominador'
            case this.subtipos[4]:
              return index === 1 ? 'Numerador Irredutível' : 'Denominador Irredutível'
            case this.subtipos[5]:
              return index === 1 ? 'Limite Inferior (º)' : 'Limite Superior (º)'
          }
          return 'Resposta ' + index + (index==1 ? ' (Correta)' : ' (Incorreta)')
        case this.tipos[2]:
          return 'Resposta ' + (index==1 ? ' Correta' : ' Incorreta')
        //case this.tipos[3]:
        //  return ''
        default: 
          return 'Resposta'
      }
    },

    wipeSubtema(){
      this.temaSelected = ''
      this.subtemaSelected = ''
    },

    criarJSONPergunta(){
      let json = {
        questao: this.questao,
        auxiliar: this.auxiliar,
        unidade: this.unidade,
        tema: this.catalogoTemas[this.temaSelected],
        subtema: this.getSubtemaNr(this.catalogoTemas[this.temaSelected]),
        examesn: 0,
        pista: '',
        idexame: '',
        niveldificuldade: parseInt(this.nivelSel),
        ano: parseInt(this.anoSel),

        resolucao: '', // WIP
        figura: '' // WIP
      }

      let i
      for(i = 0; i < this.numResps; i++){
        let idx = i+1
        //json['resposta' + idx] = this.subtipoSel === this.subtiposEM[1] ? 'bckqr/' + this.respsImagem[i].img.name : this.resps[i].resp
        json['resposta' + idx] = this.subtipoSel === this.subtiposEM[1] 
                                                      ? ''
                                                      : this.tipoSel === this.tipos[3]
                                                      ? this.$refs.clickable_grid.getCurrentString()
                                                      : this.resps[i].resp // para já vazio porque nao importa ate ao upload
      }

      for(; i < 6; i++){
        let idx = i+1
        json['resposta' + idx] = ''
      }

      if(this.exameToggle){
        json['examesn'] = 1
        json['idexame'] = this.exameValue
      }

      switch(this.tipoSel){
        case this.tipos[0]:
          switch(this.numResps){
            case 3:
              json['tipo'] = 4
              break
            case 4:
              json['tipo'] = this.subtipoSel === this.subtiposEM[0] ? 0 : 2 
              break
            case 5:
              json['tipo'] = 5 
              break
            case 6:
              json['tipo'] = 6 
              break
          }
          break
        case this.tipos[1]:
          json['tipo'] = 1
          break
        case this.tipos[2]:
          json['tipo'] = 3
          break
        case this.tipos[3]:
          json['tipo'] = 7
          break
      }

      this.pergunta = json
      //console.log("JSON CRIADO", this.pergunta)
       axios.post(host + 'perguntas/converter', this.pergunta)
        .then(data => {
          this.perguntaPreview = data.data
        })
        .catch(e => console.log(e)) 
      //this.$refs.converte_pergunta.rawToHtml()
    },

    submeterImagens(){
      //console.log(this.respsImagem)

      Swal.fire({
        title: "Submissão a decorrer...",
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      let formData = new FormData()
      let auxObj = { }
      let curr_counter = 3
      this.respsImagem.forEach( (obj,idx) => {
        let uniqueName = `${obj.img.name.substr(0, obj.img.name.lastIndexOf('.')) || obj.img.name}_0${curr_counter++}.png` 
        formData.append("files",obj.img, uniqueName)
        let counter = {"resp": [], "last": 0}
        auxObj[uniqueName] = auxObj[uniqueName] || counter
        auxObj[uniqueName].resp.push( idx + 1) // { "1.png" -> { "resp": [1,2], "last: 0" } }
      });

/*       if(this.picToggle) {
        formData.append("files", this.imgSel)
        auxObj.main = this.imgSel.name //{ "1.png" -> { "resp": [1,2], "last: 0" }, "main" -> "y.png" }
      } */

      if(this.addImagemToggle){
        let blobRes1 = this.dataURItoBlob(this.$refs.imagemRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png"))
        formData.append("files", blobRes1, this.imgImgName) // porque é blob
        auxObj.main = this.imgImgName
      }

      if(this.addResolucaoToggle){
        let blobRes = this.dataURItoBlob(this.$refs.resolucaoRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png"))
        formData.append("files", blobRes, this.resImgName) // porque é blob
        auxObj.resol = this.resImgName
      }

      //console.log(auxObj)

      axios.post(host + 'upload', formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
        .then(data => {
          //console.log(data.data)
          let check = true
          let check2 = true
          data.data.forEach(obj => {
            let urlstr = obj.url
            let noExtension = urlstr.substr(0, urlstr.lastIndexOf('.')) || urlstr;

            if(check && auxObj.main === obj.name) {
              this.pergunta.figura = `bckqr${noExtension}`
              check = false
            }
            else if(check2 && auxObj.resol === obj.name){
              this.pergunta.resolucao = `bckqr_r${noExtension}`
              check2 = false
            }
            else {
              let last = auxObj[obj.name].last++
              let np = auxObj[obj.name].resp[last]
              this.pergunta[`resposta${np}`] = `bckqr${noExtension}`
            }
          })

          axios.post(host + 'quarentena', this.pergunta)
            .then(resultAxios => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Questão submetida para aprovação com sucesso!",
                confirmButtonColor: "#009263",
              })
              .then(result => {
                if(result.isConfirmed) {
                  this.$router.push({
                      name: 'History', 
                      params: { tab_sel: '1', item_sel: resultAxios.data.cod }
                  });
                }
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                  confirmButtonColor: "#009263",
                })
              })
            })
            .catch(() => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                confirmButtonColor: "#009263",
              })
            })   
        })
        .catch(() => {
          Swal.close();
            Swal.fire({
              icon: "error",
              title: "Ocorreu um erro inesperado no upload das imagens. Por favor tente novamente mais tarde.",
              confirmButtonColor: "#009263",
            })
        })
    },

    submeterTexto(){
      if(this.addImagemToggle || this.addResolucaoToggle){
        Swal.fire({
          title: "Submissão a decorrer...",
          showConfirmButton: false,
          allowOutsideClick: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        let auxObj = {}

        let formData = new FormData()
/*         if(this.picToggle){
          formData.append("files",this.imgSel)
          auxObj['main'] = this.imgSel.name
        } */

        if(this.addImagemToggle){
          let blobRes1 = this.dataURItoBlob(this.$refs.imagemRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png"))
          formData.append("files", blobRes1, this.imgImgName) // porque é blob
          auxObj['main'] = this.imgImgName
        }

        if(this.addResolucaoToggle){
          let blobRes = this.dataURItoBlob(this.$refs.resolucaoRef.$refs.tuiImageEditor.invoke('toDataURL', "image/png"))
          formData.append("files", blobRes, this.resImgName) // porque é blob
          auxObj['resol'] = this.resImgName
        }

        //console.log(formData)

        axios.post(host + 'upload', formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
        .then(data => {
          data.data.forEach(obj => {
            let urlstr = obj.url
            let noExtension = urlstr.substr(0, urlstr.lastIndexOf('.')) || urlstr;
            if(auxObj['main'] === obj.name){
              this.pergunta.figura = `bckqr${noExtension}`
            }
            else {
              this.pergunta.resolucao = `bckqr_r${noExtension}`
            }
          })
          //this.pergunta.figura = `bckqr${data.data[0].url}`
          //console.log("SENDING", this.pergunta)
          axios.post(host + 'quarentena', this.pergunta)
            .then(resultAxios => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Questão submetida para aprovação com sucesso!",
                confirmButtonColor: "#009263",
              })
              .then(result => {
                if(result.isConfirmed) {
                  this.$router.push({
                      name: 'History', 
                      params: { tab_sel: '1', item_sel: resultAxios.data.cod }
                  });
                }
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                  confirmButtonColor: "#009263",
                })
              }) 
            })
            .catch(() => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                confirmButtonColor: "#009263",
              })
            }) 
          })
        .catch(() => {
          Swal.close();
            Swal.fire({
              icon: "error",
              title: "Ocorreu um erro inesperado no upload das imagens. Por favor tente novamente mais tarde.",
              confirmButtonColor: "#009263",
            })
        })
      }
      else {
        Swal.fire({
          title: "Submissão a decorrer...",
          showConfirmButton: false,
          allowOutsideClick: false,
          willOpen: () => {
              Swal.showLoading();
            },
          });

          axios.post(host + 'quarentena', this.pergunta)
            .then(resultAxios => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Questão submetida para aprovação com sucesso!",
                confirmButtonColor: "#009263",
              })
              .then(result => {
                if(result.isConfirmed) {
                  this.$router.push({
                      name: 'History', 
                      params: { tab_sel: '1', item_sel: resultAxios.data.cod }
                  });
                }
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                  confirmButtonColor: "#009263",
                })
              }) 
            })
            .catch(() => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Ocorreu um erro inesperado na submissão da questão. Por favor tente novamente mais tarde.",
                confirmButtonColor: "#009263",
              })
            }) 
      }
    },
    
    submeter(){
      this.subtipoSel === this.subtiposEM[1] ? this.submeterImagens() : this.submeterTexto()
    },
  },

  computed: {
    getCorrectTipo(){
      switch(this.tipoSel){
        case this.tipos[0]:
          return this.subtiposEM
        case this.tipos[3]:
          return this.subtiposSim
        default: 
          return this.subtipos
      }
    },
    isFull(){
      return this.numResps >= 6
    },
    isEmpty(){
      if(this.tipoSel === this.tipos[0]) return this.numResps <= 3
      else return this.numResps <= 2
    },
    isVF(){
      return this.tipoSel === this.tipos[2]
    },
    isEM(){
      return this.tipoSel === this.tipos[0] || this.subtipoSel === this.subtipos[2]
    },
    isRA(){
      return this.tipoSel === this.tipos[1]
    },
    hasUnidade(){
      return this.tipoSel === this.tipos[1] && this.subtipoSel!==this.subtipos[5]
    },
    hasError(){
      let invalidResps = false  

      if(this.tipoSel===this.tipos[3] && this.clickableMounted){
        invalidResps = this.$refs.clickable_grid.isInvalidString()
      }
      else {
        for(let i = 0; i < this.numResps; i++) {

          if(this.subtipoSel === this.subtiposEM[0] && this.resps[i].resp === ''){
            invalidResps = true
            //console.log("RESPS: ERROR ON ", i, this.resps[i].resp )
            break
          }
          else if (this.subtipoSel === this.subtiposEM[1] && !this.respsImagem[i].img){
            invalidResps = true
            //console.log("RESPS IMG: ERROR ON ", i, this.respsImagem[i].img )
            break
          }
        }
      }
      
      return this.temaSelected === '' ||
          this.subtemaSelected === '' ||
          (this.exameToggle ? this.exameValue === '' : false) || 
          //(this.picToggle ? this.imgSel === null : false) || 
          (this.nivelSel < 1 && this.nivelSel > 4) ||
          (this.anoSel < 1 && this.anoSel > 9) ||
          this.questao === '' ||
          invalidResps
    },
    hasNoResolucao(){
      if(!this.isMounted){
        //console.log("nope 1")
        return true
      }

      if(this.e1 === 5 && !this.userResUpload){
        //console.log("nope 2")
        return true
      }
      if(this.loadingPage || this.e1 === 1){
        return true
      }
      let name = this.$refs.resolucaoRef.$refs.tuiImageEditor.invoke('getImageName')
      //console.log("IMAGE NAME: ", name)
      return this.e1 === 5 && name === ''
    },

    hasNoImagem(){
      if(!this.isMounted){
        //console.log("nope 1")
        return true
      }

      if(this.e1 === 3 && !this.userImgUpload){
        //console.log("nope 2")
        return true
      }
      if(this.loadingPage || this.e1 === 1){
        return true
      }
      let name = this.$refs.imagemRef.$refs.tuiImageEditor.invoke('getImageName')
      //console.log("IMAGE NAME: ", name)
      return this.e1===3 && name === ''
    },

    getMaxWidth(){
      //console.log(this.resolCard)
      return this.resolCard
    }
  },

  async mounted() {
    await this.getTemas()
    await this.getExames()
    this.isMounted = true
    this.loadingPage = false
  }
};
</script>

<style scoped>
/* Vue-grid-layout */
.btnItem {
  width: 100%;
  height: 100%;
  display: block;
}
.vue-grid-layout {
  background: #eee;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid black;
}
.input {
  background-color: white;
  border: 2px solid #009263;
  box-shadow: 1px 1px 1px 0 lightgray inset;
  padding: 2px 8px;
  height: 55px;
  line-height: 45px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: auto;
}
.unidade {
  float: right;
}
.codquestao {
  margin-top: 5px;
}
.selectExame {
  margin-top: -16px;
}
.selectNivel {
  margin-top: -2px;
}
#exame {
  padding: 5px 10px;
  font-size: 12px;
}
.v-stepper__step{
  font-size: smaller
}
</style>