<template>
  <v-row class="ml-2">
  <v-col cols="8">
         <!-- Vertical Grid -->
    <div
      v-if="type === 'v'"
    >
      <v-row>
        <v-col cols="6">
          <div style="margin-top:10px;">
            <grid-layout
              :layout.sync="grid.layoutEsq"
              :col-num="21"
              :row-height="18"
              :is-draggable="grid.draggable"
              :is-resizable="grid.resizable"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
             <div v-for="item in grid.layoutEsq" :key="item.i" @click="clickedGridItem(item)">
              <grid-item
                :style="corGridEsq(item.i)"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
              >
              </grid-item>
             </div>
            </grid-layout>
          </div>
        </v-col>
        <v-divider
          style="border: 5px solid black;"
          class="mx-n6 background-color: black"
          vertical
        ></v-divider>
        <v-col cols="6">
          <div style="margin-top:10px;">
            <grid-layout
              :layout.sync="grid.layoutDir"
              :col-num="21"
              :row-height="18"
              :is-draggable="grid.draggable"
              :is-resizable="grid.resizable"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
              <div v-for="item in grid.layoutDir" :key="item.i" @click="clickedGridItem(item)">
                <grid-item
                  :style="corGridDir(item.i)"
                  :x="item.x"
                  :y="item.y"
                  :w="item.w"
                  :h="item.h"
                  :i="item.i"
                >
                </grid-item>
              </div>
            </grid-layout>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Horizontal Grid -->
    <div
      v-else
    >
      <v-row no-gutters justify="center" align="center">
        <v-col cols="8">
          <div class="my-n3">
            <grid-layout
              :layout.sync="grid.layoutTop"
              :col-num="26"
              :row-height="18"
              :is-draggable="grid.draggable"
              :is-resizable="grid.resizable"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
            <div v-for="item in grid.layoutTop" :key="item.i" @click="clickedGridItem(item)">
              <grid-item
                :style="corGridTop(item.i)"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
              >
              </grid-item>
            </div>
            </grid-layout>
          </div>
        </v-col>

        <v-col cols="8">
          <v-divider
            style="border: 5px solid black;"
            class="background-color: black"
          ></v-divider>
        </v-col>
        <v-col cols="8">
          <div class="my-n3">
            <grid-layout
              :layout.sync="grid.layoutBtm"
              :col-num="26"
              :row-height="18"
              :is-draggable="grid.draggable"
              :is-resizable="grid.resizable"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
            <div v-for="item in grid.layoutBtm" :key="item.i" @click="clickedGridItem(item)">
              <grid-item
                :style="corGridBtm(item.i)"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
              >
              </grid-item>
            </div>
            </grid-layout>
          </div>
        </v-col>
      </v-row>
    </div> 
    </v-col>
    </v-row>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
export default {
    components: {
        GridLayout,
        GridItem
    },

    props: {
        type: String, // v h
        axis: String, // c b e d
        respValue: String
    },

    data() {
        return {
            grid: {
                layoutTop: [
                { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
                { x: 4, y: 0, w: 5, h: 2, i: "10", static: true },
                { x: 8, y: 0, w: 5, h: 2, i: "15", static: true },
                { x: 12, y: 0, w: 5, h: 2, i: "20", static: true },
                { x: 16, y: 0, w: 5, h: 2, i: "25", static: true },
                { x: 20, y: 0, w: 5, h: 2, i: "30", static: true },

                { x: 0, y: 1.4, w: 5, h: 2, i: "4", static: true },
                { x: 4, y: 1.4, w: 5, h: 2, i: "9", static: true },
                { x: 8, y: 1.4, w: 5, h: 2, i: "14", static: true },
                { x: 12, y: 1.4, w: 5, h: 2, i: "19", static: true },
                { x: 16, y: 1.4, w: 5, h: 2, i: "24", static: true },
                { x: 20, y: 1.4, w: 5, h: 2, i: "29", static: true },

                { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
                { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
                { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
                { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
                { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
                { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },

                { x: 0, y: 4.2, w: 5, h: 2, i: "2", static: true },
                { x: 4, y: 4.2, w: 5, h: 2, i: "7", static: true },
                { x: 8, y: 4.2, w: 5, h: 2, i: "12", static: true },
                { x: 12, y: 4.2, w: 5, h: 2, i: "17", static: true },
                { x: 16, y: 4.2, w: 5, h: 2, i: "22", static: true },
                { x: 20, y: 4.2, w: 5, h: 2, i: "27", static: true },

                { x: 0, y: 5.6, w: 5, h: 2, i: "1", static: true },
                { x: 4, y: 5.6, w: 5, h: 2, i: "6", static: true },
                { x: 8, y: 5.6, w: 5, h: 2, i: "11", static: true },
                { x: 12, y: 5.6, w: 5, h: 2, i: "16", static: true },
                { x: 16, y: 5.6, w: 5, h: 2, i: "21", static: true },
                { x: 20, y: 5.6, w: 5, h: 2, i: "26", static: true },
                ],
                layoutBtm: [
                { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
                { x: 4, y: 0, w: 5, h: 2, i: "6", static: true },
                { x: 8, y: 0, w: 5, h: 2, i: "11", static: true },
                { x: 12, y: 0, w: 5, h: 2, i: "16", static: true },
                { x: 16, y: 0, w: 5, h: 2, i: "21", static: true },
                { x: 20, y: 0, w: 5, h: 2, i: "26", static: true },

                { x: 0, y: 1.4, w: 5, h: 2, i: "2", static: true },
                { x: 4, y: 1.4, w: 5, h: 2, i: "7", static: true },
                { x: 8, y: 1.4, w: 5, h: 2, i: "12", static: true },
                { x: 12, y: 1.4, w: 5, h: 2, i: "17", static: true },
                { x: 16, y: 1.4, w: 5, h: 2, i: "22", static: true },
                { x: 20, y: 1.4, w: 5, h: 2, i: "27", static: true },

                { x: 0, y: 2.8, w: 5, h: 2, i: "3", static: true },
                { x: 4, y: 2.8, w: 5, h: 2, i: "8", static: true },
                { x: 8, y: 2.8, w: 5, h: 2, i: "13", static: true },
                { x: 12, y: 2.8, w: 5, h: 2, i: "18", static: true },
                { x: 16, y: 2.8, w: 5, h: 2, i: "23", static: true },
                { x: 20, y: 2.8, w: 5, h: 2, i: "28", static: true },

                { x: 0, y: 4.2, w: 5, h: 2, i: "4", static: true },
                { x: 4, y: 4.2, w: 5, h: 2, i: "9", static: true },
                { x: 8, y: 4.2, w: 5, h: 2, i: "14", static: true },
                { x: 12, y: 4.2, w: 5, h: 2, i: "19", static: true },
                { x: 16, y: 4.2, w: 5, h: 2, i: "24", static: true },
                { x: 20, y: 4.2, w: 5, h: 2, i: "29", static: true },

                { x: 0, y: 5.6, w: 5, h: 2, i: "5", static: true },
                { x: 4, y: 5.6, w: 5, h: 2, i: "10", static: true },
                { x: 8, y: 5.6, w: 5, h: 2, i: "15", static: true },
                { x: 12, y: 5.6, w: 5, h: 2, i: "20", static: true },
                { x: 16, y: 5.6, w: 5, h: 2, i: "25", static: true },
                { x: 20, y: 5.6, w: 5, h: 2, i: "30", static: true },
                ],
                layoutEsq: [
                { x: 0, y: 0, w: 5, h: 2, i: "5", static: true },
                { x: 4, y: 0, w: 5, h: 2, i: "4", static: true },
                { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
                { x: 12, y: 0, w: 5, h: 2, i: "2", static: true },
                { x: 16, y: 0, w: 5, h: 2, i: "1", static: true },
                { x: 0, y: 1.6, w: 5, h: 2, i: "10", static: true },
                { x: 4, y: 1.6, w: 5, h: 2, i: "9", static: true },
                { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
                { x: 12, y: 1.6, w: 5, h: 2, i: "7", static: true },
                { x: 16, y: 1.6, w: 5, h: 2, i: "6", static: true },
                { x: 0, y: 3.2, w: 5, h: 2, i: "15", static: true },
                { x: 4, y: 3.2, w: 5, h: 2, i: "14", static: true },
                { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
                { x: 12, y: 3.2, w: 5, h: 2, i: "12", static: true },
                { x: 16, y: 3.2, w: 5, h: 2, i: "11", static: true },
                { x: 0, y: 4.8, w: 5, h: 2, i: "20", static: true },
                { x: 4, y: 4.8, w: 5, h: 2, i: "19", static: true },
                { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
                { x: 12, y: 4.8, w: 5, h: 2, i: "17", static: true },
                { x: 16, y: 4.8, w: 5, h: 2, i: "16", static: true },
                { x: 0, y: 6.4, w: 5, h: 2, i: "25", static: true },
                { x: 4, y: 6.4, w: 5, h: 2, i: "24", static: true },
                { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
                { x: 12, y: 6.4, w: 5, h: 2, i: "22", static: true },
                { x: 16, y: 6.4, w: 5, h: 2, i: "21", static: true },
                { x: 0, y: 8, w: 5, h: 2, i: "30", static: true },
                { x: 4, y: 8, w: 5, h: 2, i: "29", static: true },
                { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
                { x: 12, y: 8, w: 5, h: 2, i: "27", static: true },
                { x: 16, y: 8, w: 5, h: 2, i: "26", static: true },
                ],
                layoutDir: [
                { x: 0, y: 0, w: 5, h: 2, i: "1", static: true },
                { x: 4, y: 0, w: 5, h: 2, i: "2", static: true },
                { x: 8, y: 0, w: 5, h: 2, i: "3", static: true },
                { x: 12, y: 0, w: 5, h: 2, i: "4", static: true },
                { x: 16, y: 0, w: 5, h: 2, i: "5", static: true },
                { x: 0, y: 1.6, w: 5, h: 2, i: "6", static: true },
                { x: 4, y: 1.6, w: 5, h: 2, i: "7", static: true },
                { x: 8, y: 1.6, w: 5, h: 2, i: "8", static: true },
                { x: 12, y: 1.6, w: 5, h: 2, i: "9", static: true },
                { x: 16, y: 1.6, w: 5, h: 2, i: "10", static: true },
                { x: 0, y: 3.2, w: 5, h: 2, i: "11", static: true },
                { x: 4, y: 3.2, w: 5, h: 2, i: "12", static: true },
                { x: 8, y: 3.2, w: 5, h: 2, i: "13", static: true },
                { x: 12, y: 3.2, w: 5, h: 2, i: "14", static: true },
                { x: 16, y: 3.2, w: 5, h: 2, i: "15", static: true },
                { x: 0, y: 4.8, w: 5, h: 2, i: "16", static: true },
                { x: 4, y: 4.8, w: 5, h: 2, i: "17", static: true },
                { x: 8, y: 4.8, w: 5, h: 2, i: "18", static: true },
                { x: 12, y: 4.8, w: 5, h: 2, i: "19", static: true },
                { x: 16, y: 4.8, w: 5, h: 2, i: "20", static: true },
                { x: 0, y: 6.4, w: 5, h: 2, i: "21", static: true },
                { x: 4, y: 6.4, w: 5, h: 2, i: "22", static: true },
                { x: 8, y: 6.4, w: 5, h: 2, i: "23", static: true },
                { x: 12, y: 6.4, w: 5, h: 2, i: "24", static: true },
                { x: 16, y: 6.4, w: 5, h: 2, i: "25", static: true },
                { x: 0, y: 8, w: 5, h: 2, i: "26", static: true },
                { x: 4, y: 8, w: 5, h: 2, i: "27", static: true },
                { x: 8, y: 8, w: 5, h: 2, i: "28", static: true },
                { x: 12, y: 8, w: 5, h: 2, i: "29", static: true },
                { x: 16, y: 8, w: 5, h: 2, i: "30", static: true },
                ],
                draggable: false,
                resizable: false,
            },
            
            simetriaSel: '',
            clickedItems: []
        }
    },

    methods: {
        getCurrentString(){
          return this.simetriaSel
        },
        isInvalidString(){
          return this.simetriaSel.match(/^[vh]-[cbed]$/)
        },
        formatSimetriaString(){
            let res = `${this.type}-${this.axis}`
            this.clickedItems.forEach(item => {
              res += `-${item}`
            })
            this.simetriaSel = res
            //console.log(this.simetriaSel)
        },
        // Grid Horizontal
        clickedGridItem(item){
          //console.log("antes do click")
          //console.log(this.simetriaSel)
            this.clickedItems.indexOf(item.i) === -1 ? this.clickedItems.push(item.i) : this.clickedItems.splice(this.clickedItems.indexOf(item.i), 1);
            this.clickedItems.sort((a,b) => a-b)
            this.formatSimetriaString()
          //console.log(this.simetriaSel)
          //console.log("depois do click")
        },
        corGridTop(index) {
            const layout = this.simetriaSel
            let idxList = layout.split("-");
            const lado = idxList[1];

            idxList = idxList.splice(2);

            if (lado === "c")
                return idxList.includes(index)
                ? { background: "#ff6600" }
                : { background: "#eee" };
            else {
                return idxList.includes(index)
                ? { background: "#4CC74D" } 
                : { background: "#eee" };
            }
        },
        corGridBtm(index) {
            const layout = this.simetriaSel
            let idxList = layout.split("-");
            const lado = idxList[1];

            idxList = idxList.splice(2);

            if (lado === "b")
                return idxList.includes(index)
                ? { background: "#ff6600" }
                : { background: "#eee" };
            else {
                return idxList.includes(index)
                ? { background: "#4CC74D" } 
                : { background: "#eee" };
            }
        },
        // Grid Vertical
        corGridEsq(index) {
            const layout = this.simetriaSel
            let idxList = layout.split("-");
            const lado = idxList[1];

            idxList = idxList.splice(2);

            if (lado === "e")
                return idxList.includes(index)
                ? { background: "#ff6600" }
                : { background: "#eee" };
            else {
                return idxList.includes(index)
                ? { background: "#4CC74D" } 
                : { background: "#eee" };
            }
        },
        corGridDir(index) {
            const layout = this.simetriaSel 
            let idxList = layout.split("-");
            const lado = idxList[1];

            idxList = idxList.splice(2);

            if (lado === "d")
                return idxList.includes(index)
                ? { background: "#ff6600" }
                : { background: "#eee" };
            else {
                return idxList.includes(index)
                ? { background: "#4CC74D" } 
                : { background: "#eee" };
            }
        },
    },

    computed: {
    },

    created() {
      if(this.respValue){
        this.simetriaSel = this.respValue
        let iterate = this.respValue.split('-')
        let idx = 2
        while(idx < iterate.length){
          this.clickedItems.push(iterate[idx++])
        }
      }
      else {
        this.simetriaSel = `${this.type}-${this.axis}`
      }
    },

    watch: {
        $props: {
            handler() {
                this.clickedItems = [];
                this.simetriaSel = `${this.type}-${this.axis}`
            },
            deep: true,
            immediate: true,
        },
    },

}
</script>

<style>
.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid black;
}
</style>