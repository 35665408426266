<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Questões Aprovadas</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página mostra todas as questões que foram aprovadas e que se encontram na base de dados atualmente sob a forma de tabela. 
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. De modo a facilitar a navegação, existem vários filtros que atuam sobre os campos de cada pergunta. Para além dos filtros, é possível ordenar por ordem alfabética qualquer uma das colunas da tabela através de um clique nas mesmas. Cada clique sucessivo alterna entre ordem natural, ascendente e descendente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada linha da tabela tem um botão <v-icon>mdi-chevron-down</v-icon> que abre o pré-visualizador da questão. Após isso, tem também um botão que permite eliminar a questão da base de dados
                    <v-btn class="white--text" small color="#d33"><v-icon medium >mdi-trash-can-outline</v-icon></v-btn>, um para proceder à edição da questão <v-btn class="white--text" small color="#ffa500"><v-icon medium >mdi-note-edit-outline</v-icon></v-btn> e um que permite esconder a questão na aplicação <b>Quero Resolver Questões de...</b> <v-btn class="white--text" small color="grey"><v-icon medium >mdi-eye-off</v-icon></v-btn> .
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Caso seja realizada a edição da questão, esta é removida da base de dados e adicionada como uma nova submissão nas <b style="color:#009263">Questões Propostas</b>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Caso remova uma questão, a operação é <b>permanente</b> e não é possível recuperá-la.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
          <!-- Filtros -->
          <center>
            <v-container >
              <v-row>
               <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                  <v-textarea
                   color="#009263"
                   rows="1"
                   auto-grow
                   hide-details
                   clearable
                   @input="codigo = $event !== null ? $event : ``"
                   outlined
                   type="text"
                   label="Código"
                   prepend-icon="mdi-code-tags"
                   v-model="codigo">
                 </v-textarea>
               </v-col>
               
               <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                 <v-select
                     color="#009263"
                     item-color="green"
                     outlined
                     clearable
                     flat
                     v-model="tipoSel"
                     @input="tipoSel = $event !== null ? $event : ``"
                     @change="changeTipo()"
                     :items="tipos"
                     label="Tipo"
                     hide-details
                     prepend-icon="mdi-clipboard-list-outline"
                   ></v-select>
               </v-col>
               <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-select
                        color="#009263"
                        item-color="green"
                        outlined
                        flat
                        clearable
                        :disabled="tipoSel!=='Resposta Aberta'"
                        v-model="subtipoSel"
                        @input="subtipoSel = $event !== null ? $event : ``"
                        @change="changeSubtipo()"
                        :items="subtipos"
                        label="Subtipo"
                        hide-details
                        prepend-icon="mdi-flag-variant-outline"
                      ></v-select>
                    </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                    <v-text-field
                      color="#009263"
                      outlined
                      hide-details
                      clearable
                      v-model="nivelSel"
                      @input="nivelSel = $event !== null ? $event : ``"
                      type="number"
                      label="Nível"
                      min="1"
                      max="4"
                      prepend-icon="mdi-head-cog-outline"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                   <v-text-field
                      color="#009263"
                      outlined
                      hide-details
                      clearable
                      v-model="anoSel"
                      @input="anoSel = $event !== null ? $event : ``"
                      type="number"
                      label="Ano"
                      min="1"
                      max="9"
                      prepend-icon="mdi-ruler-square-compass"
                    ></v-text-field>
                </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <v-combobox
                    color="#009263"
                    item-color="green"
                    hide-details
                    clearable
                    @input="exameValue = $event !== null ? $event : ``"
                    outlined
                    v-model="exameValue"
                    :items="exames"
                    flat
                    label="Exame"
                    prepend-icon="mdi-school-outline"
                  ></v-combobox>
                </v-col>
              </v-row>     
              <v-row>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                   <v-combobox
                     color="#009263"
                     item-color="green"
                     outlined
                     clearable
                     flat
                     v-model="temaSelected"
                     @click:clear="wipeSubtema"
                     :items="temasFiltro"
                     label="Tema"
                     hide-details
                     @change="changeSubtemas"
                     prepend-icon="mdi-format-list-bulleted"
                   ></v-combobox>
                 </v-col>
                 <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                   <v-combobox
                     color="#009263"
                     item-color="green"
                     v-model="subtemaSelected"
                     outlined
                     clearable
                     hide-details
                     flat
                     :items="subtemasFiltro"
                     label="Subtema"
                     prepend-icon="mdi-format-list-text">
                   ></v-combobox>
                 </v-col>
              </v-row>
              <v-row>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                 <v-textarea
                   color="#009263"
                   rows="1"
                   auto-grow
                   clearable
                   @input="questao = $event !== null ? $event : ``"
                   hide-details
                   outlined
                   type="text"
                   label="Questão"
                   prepend-icon="mdi-clipboard-edit-outline"
                   v-model="questao">
                 </v-textarea>
                 
               </v-col>
               <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                      color="#009263"
                      outlined
                      hide-details
                      clearable
                      v-model="respostaSel"
                      @input="respostaSel = $event !== null ? $event : ``"
                      label="Respostas 1 a 6"
                      prepend-icon="mdi-pencil"
                    ></v-text-field>
               </v-col>
             </v-row>
            </v-container>
          </center>
          <br />
          <!-- Tabela -->
          <v-container v-if="loading">
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
          </v-container>
          <v-container v-else>
            <v-data-table
              no-data-text="Não existem Dados"
              :footer-props="{
                'items-per-page-text': 'Mostrar',
                'items-per-page-options': [10, 25, 50],
                'items-per-page-all-text': 'Todos',
              }"
              dense
              show-expand
              :items-per-page=25
              :headers="headers" 
              :items="getFilteredItems"
              item-key="cod"
              class="elevation-1"
              color="#009263"
            >
            <template v-slot:[`footer.page-text`]="props">
              {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <Preview :pergunta="item"/>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="editar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-8" color="#ffa500">
                      <v-icon >
                      mdi-note-edit-outline
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  @click="eliminar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-2" color="#d33">
                      <v-icon >
                      mdi-trash-can-outline
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  @click="esconder(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-2" color="grey">
                      <v-icon >
                      mdi-eye-off
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Esconder</span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:[`item.questao`]="{ item }">
                <span v-html="item.questao"></span>
            </template>
            <template v-slot:[`item.tipo`]="{ item }">
                {{getTipo(item.tipo)}}
            </template>
            <template v-slot:[`item.idexame`]="{ item }">
                {{getExame(item.idexame)}}
            </template>
            <template v-slot:[`item.tema`]="{ item }">
                {{ getTema(item.tema) }}
            </template>
            <template v-slot:[`item.subtema`]="{ item }">
                {{ getSubtema(item.tema, item.subtema) }}
            </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const Swal = require("sweetalert2");
import Preview from "../components/Preview.vue";

export default {
  components:{
    Preview
  },
  data() {
    return {
      loading: true,
      show:false,
      results: [], 
      exames: [],
      temas: [],
      catalogoTemas: {},
      temasFiltro: [],
      subtemasFiltro: [],
      tipos: ['Escolha Múltipla', 'Resposta Aberta', 'Verdadeiro ou Falso', 'Simetria'],
      subtipos: ["1 Resposta Correta", "2 Respostas Corretas", "Várias Respostas Corretas", "Fração Redutível", "Fração Irredutível", "Utilizar Transferidor"],
      subtipoSel: '',
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'7%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'10%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'20%'},
        { text: "Exame", value: "idexame", class: "subtitle-1" ,width:'20%', align:'center'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'10%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'15%'},
        { text: "Nível", value: "niveldificuldade", class: "subtitle-1" },
        { text: "Ano", value: "ano", class: "subtitle-1"}
      ],
      exameValue: '',
      temaSelected: '',
      subtemaSelected: '',
      anoSel: '',
      nivelSel: '',
      respostaSel: '',
      questao: '',
      codigo: '',
      tipoSel: '',
      anoRules: [(v) => v && v > 0 && v < 10],
      nivelRules: [(v) => v && v > 0 && v < 5],
      auxiliar: 0
    };
  },
  methods: {
    getTema(tema){
      return this.temas[tema]?.tema 
    },

    getSubtema(tema,subtema){
      return this.temas[tema]?.subtemas[subtema]
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    changeSubtipo(){
      if (this.tipoSel !== this.tipos[1]){
        this.auxiliar = 0
      }
      else{
        switch(this.subtipoSel){
          case this.subtipos[0]:
            this.auxiliar = 1
            break
          case this.subtipos[1]:
            this.auxiliar = 1000
            break
          case this.subtipos[2]:
            this.auxiliar = 0
            break
          case this.subtipos[3]:
            this.auxiliar = 22
            break
          case this.subtipos[4]:
            this.auxiliar = 2
            break
          case this.subtipos[5]:
            this.auxiliar = 10
            break
          default:
            this.auxiliar = 0
            break
        }
      }
      //console.log("change subtipo, auxiliar: ", this.auxiliar)
    },
    changeSubtemas(){
      this.subtemaSelected = ''
      let tema = this.catalogoTemas[this.temaSelected]
      if(this.temaSelected != null) this.subtemasFiltro = Object.entries(this.temas[tema].subtemas).map(e => e[1])
      else this.subtemasFiltro = []
    },

    wipeSubtema(){
      this.temaSelected = ''
      this.subtemaSelected = ''
    },

    changeTipo(){
      this.subtipoSel = '' 
    },

    getSubtemaNr(tema){
      let subtemas = this.temas[tema].subtemas 
      if(this.temaSelected != '' && this.subtemaSelected != ''&& this.temaSelected != null && this.subtemaSelected != null) {
          for (var [key, value] of Object.entries(subtemas)) {
              if(this.subtemaSelected == value) return key
          }
      }
      return ''
    },

    async getQuestoes() {
      const data = await axios.get(host + 'perguntas?_limit=-1')
      this.results = data.data
      this.loading = false
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
      this.temasFiltro.sort((a, b) => a.localeCompare(b))
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    async getExames(){
      const data = await axios.get(host + 'perguntas/exames')
      this.exames.push('Nenhum')
      this.exames.push( ...data.data )
    },

    getExame(idexame){
      return idexame === '' ? '-' : idexame 
    },

    isInResposta(questao){
      let res = false
      for(let i=1 ; i<=6 ; i++){
        if(questao[`resposta${i}`].toString().toLowerCase().includes(this.respostaSel.toLowerCase())) {
            res = true
            break;
        }
      }
      return res
    },  

    esconder(codigo){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende esconder da plataforma "Quero Resolver Questões de..." a questão com código ${codigo}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a operação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(host + 'perguntas_escondidas/'+ codigo)
              .then( () => {
                  Swal.close();
                  Swal.fire({
                    icon: "success",
                    title: "Questão escondida com sucesso!",
                    confirmButtonColor: "#009263",
                  })
                  /* .then(result => {
                    if(result.isConfirmed) {
                      this.$router.go(0)
                    }
                  }) */
                  .then( () => {})
                  .catch(e => {throw new Error(e)}) 
              })
              .catch( () => {
                 Swal.close();
                 Swal.fire({
                   icon: "error",
                   title: "A pergunta já se encontra escondida.",
                   confirmButtonColor: "#009263",
                 })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível esconder a questão.");
        throw error;
      }
    },

    editar(codigo){
      this.$router.push( {name: 'FormEditPerguntaQuestion', params: {cod: codigo, isSubmission: false}})
    },

    eliminar(codigo){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende eliminar da base de dados a questão com código ${codigo}? A operação é permanente.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            await axios.delete(host + 'perguntas/'+ codigo);

            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Submissão removida com sucesso!",
              confirmButtonColor: "#009263",
            })
            .then(result => {
              if(result.isConfirmed) {
                this.$router.go(0)
              }
            })
            .catch(e => {throw new Error(e)}) 
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a questão.");
        throw error;
      }
    },

  },
  computed: {
    getFilteredItems() {
      let filtered = [...this.results]
              .filter(questao => {
                return questao.cod.toLowerCase().includes(this.codigo.toLowerCase()) &&
                  questao.questao.toLowerCase().includes(this.questao.toLowerCase()) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null ? questao.tema.toLowerCase() == this.catalogoTemas[this.temaSelected] : questao.tema.toLowerCase().includes('')) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null && this.subtemaSelected != '' && this.subtemaSelected != null ? questao.subtema.toLowerCase() == this.getSubtemaNr(questao.tema) : questao.subtema.toLowerCase().includes('')) &&
                  this.getTipo(questao.tipo).toLowerCase().includes(this.tipoSel.toLowerCase()) &&
                  (this.exameValue==='Nenhum' ?  questao.idexame.toLowerCase() === '' :  questao.idexame.toLowerCase().includes(this.exameValue.toLowerCase())) && 
                  questao.ano.toString().toLowerCase().includes(this.anoSel.toString().toLowerCase()) &&
                  questao.niveldificuldade.toString().toLowerCase().includes(this.nivelSel.toString().toLowerCase()) &&
                  (this.tipoSel === this.tipos[1] && this.subtipoSel !== '' && this.subtipoSel !== null ? questao.auxiliar === this.auxiliar : true) &&
                  this.isInResposta(questao)
              })
      return filtered
    }
  },
  mounted() {
    this.getTemas()
    this.getQuestoes()
    this.getExames()
  }
};
</script>

<style>
table th:not(:first-child) + th { border-left:1px solid #dddddd; }
table td:not(:first-child) + td { border-left:1px solid #dddddd; }
</style>
