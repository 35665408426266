<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Histórico de Submissões</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página mostra o histórico de todas as submissões efetuadas assim como o respetivo estado de cada uma delas.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. De modo a facilitar a navegação, existem 3 abas principais que dividem as suas submissões em 3 tabelas distintas: <v-icon>mdi-history</v-icon> denota as pendentes, <v-icon>mdi-check</v-icon> contém as aceites e <v-icon>mdi-close</v-icon> engloba as rejeitadas. Para além disso, é possível ordenar por ordem alfabética qualquer uma das colunas das tabelas através de um clique nas mesmas. Cada clique sucessivo alterna entre ordem natural, ascendente e descendente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada linha da tabela tem um botão <v-icon>mdi-chevron-down</v-icon> que abre o pré-visualizador da submissão. Após isso, tem também um botão para proceder à edição <v-btn class="white--text" small color="#ffa500"><v-icon medium >mdi-note-edit-outline</v-icon></v-btn> e um para a eliminar
                    <v-btn class="white--text" small color="#d33"><v-icon medium >mdi-trash-can-outline</v-icon></v-btn>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Só é possível editar submissões que estão <b>pendentes</b> ou que foram <b>rejeitadas</b> uma vez que as <b>aceites</b> já foram adicionadas de forma permanente à base de dados das questões.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Só é possível remover submissões que estejam <b>pendentes</b> ou que foram <b>rejeitadas</b>, pelo mesmo motivo.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. Caso necessite de editar uma submissão aprovada, navegue para <b style="color:#009263">Questões Aprovadas</b> e realize a operação nesse local.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    7. Caso remova uma submissão, a operação é <b>permanente</b> e não é possível recuperá-la.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
          <!-- Tabs -->
          <template>
            <v-card>
                <v-tabs
                v-model="tab"
                background-color="#009263"
                centered
                dark
                icons-and-text
                grow
                >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    Pendentes
                    ({{ getPending }})
                    <v-icon>mdi-history</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                    Aceites
                    ({{ getAccepted }})
                    <v-icon>mdi-check</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                    Rejeitadas
                    ({{ getRejected }})
                    <v-icon>mdi-close</v-icon>
                </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                
                <v-tab-item
                    v-for="i in 3"
                    :key="i"
                    :value="'tab-' + i"
                >
                    <!-- Tabela -->
                    <div v-if="loading">
                        <center>
                        <v-img
                            :src="require('@/assets/loading.gif')"
                            width="150px"
                            heigth="150px"
                        >
                        </v-img>
                        </center>
                    </div>
                    <div v-else>
                        <v-data-table
                        no-data-text="Não existem submissões deste tipo de momento."
                        :footer-props="{
                            'items-per-page-text': 'Mostrar',
                            'items-per-page-options': [10, 25, 50],
                            'items-per-page-all-text': 'Todos',
                        }"
                        dense
                        show-expand
                        :expanded.sync="expanded[i]"
                        :items-per-page=25
                        :headers="headers" 
                        :items="divided[i-1]"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        item-key="cod"
                        class="elevation-1"
                        color="#009263"
                        >
                        <template v-slot:[`footer.page-text`]="props">
                        {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <Preview :pergunta="item"/>
                            <v-row>
                              <v-col v-if="item.estado.valor===2" cols="12" sm="8" md="8" lg="8" xl="8">
                                <h2 style="color:#009263" class="ml-8 text-left my-2">
                                  <span>Submissão Rejeitada</span>
                                </h2>
                                <ul class="ml-8">
                                  <li>
                                    <span>Decisão por: <b>{{item.estado.decisao_por}}</b></span>
                                  </li>
                                </ul>
                                <v-textarea
                                  color="#009263"
                                  class="ml-8 my-4"
                                  rows="1"
                                  auto-grow
                                  hide-details
                                  outlined
                                  type="text"
                                  label="Comentário"
                                  readonly
                                  :value="item.estado.comentario === '' ? 'Não existe comentário associado.' : item.estado.comentario">
                                </v-textarea>

                              </v-col>
                              <v-col v-else-if="item.estado.valor===1" cols="12" sm="8" md="8" lg="8" xl="8">
                                <h2 style="color:#009263" class="ml-8 text-left my-2">
                                  <span>Submissão Aceite</span>
                                </h2>
                                <ul class="ml-8 my-2">
                                  <li>
                                    <span>Decisão por: <b>{{item.estado.decisao_por}}</b></span>
                                  </li>
                                </ul>
                              </v-col>
                            </v-row>
                            
                            <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="item.estado.valor === 1" @click="editar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-8" color="#ffa500">
                                <v-icon >
                                mdi-note-edit-outline
                                </v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="item.estado.valor === 1"  @click="eliminar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-2" color="#d33">
                                <v-icon >
                                mdi-trash-can-outline
                                </v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar</span>
                            </v-tooltip>
                        </td>
                        </template>
                        <template v-slot:[`item.questao`]="{ item }">
                            <span v-html="item.questao"></span>
                        </template>
                        <template v-slot:[`item.tipo`]="{ item }">
                            {{ getTipo(item.tipo) }}
                        </template>
                        <template v-slot:[`item.tema`]="{ item }">
                            {{ getTema(item.tema) }}
                        </template>
                        <template v-slot:[`item.subtema`]="{ item }">
                            {{ getSubtema(item.tema, item.subtema) }}
                        </template>
                        <template v-slot:[`item.estado.data_submissao`]="{ item }">
                            {{ formatDate(item.estado.data_submissao) }}
                        </template>
                        <template v-slot:[`item.estado.data_alteracao`]="{ item }">
                            {{ formatDate(item.estado.data_alteracao) }}
                        </template>
                        <!--
                        <template v-slot:[`item.estado.valor`]="{ item }">
                            {{ getEstado(item.estado.valor) }}
                        </template>
                        -->
                        </v-data-table>
                    </div>
                </v-tab-item>

                </v-tabs-items>
            </v-card>
          </template> 

        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require("moment")
const Swal = require("sweetalert2");

import Preview from "../components/Preview.vue";

export default {
  components:{
    Preview
  },
  props: {
    tab_sel: {
      default: "1",
      type: String
    },
    item_sel: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      sortBy: 'estado.data_alteracao',
      sortDesc: true,

      tab: null,
      loading: true,
      show:false,
      results: [], 
      temas: [],
      catalogoTemas: {},
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'7%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'10%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'20%'},
        //{ text: "Exame", value: "idexame", class: "subtitle-1" ,width:'20%'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'10%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'15%'},
        //{ text: "Nível", value: "niveldificuldade", class: "subtitle-1" },
        //{ text: "Ano", value: "ano", class: "subtitle-1"}
        //{ text: "Decisão Por", value: "estado.decisao_por", class: "subtitle-1"},
        { text: "Data de Submissão", value: "estado.data_submissao", class: "subtitle-1"},
        { text: "Última Alteração", value: "estado.data_alteracao", class: "subtitle-1"}
        //{ text: "Estado", value: "estado.valor", class: "subtitle-1"},
      ],
      divided: [],

      expanded: { // porque nao funciona em [] em renders dinamicos
        1: [],
        2: [],
        3: []
      }
    };
  },
  methods: {
    getTema(tema){
      return this.temas[tema] ? this.temas[tema].tema : ''
    },

    getSubtema(tema,subtema){
      return this.temas[tema] ? this.temas[tema].subtemas[subtema] : ''
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    async getQuestoesQuarentena() {
      const data = await axios.get(host + 'quarentena/submissoes')
      this.divided = data.data.reduce( (acc, val) => {
          acc[val.estado.valor].push(val)
          return acc
      }, { 0: [], 1: [], 2: [] })
      //console.log(this.divided)
      this.results = data.data
      this.loading = false
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    getEstado(valor){
        switch(valor){
            case 0:
                return 'Pendente'
            case 1:
                return 'Aceite'
            case 2:
                return 'Rejeitada'
        }
    },

    formatDate(date){
      return moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY, HH:mm:ss') : 'Indisponível'
    },

    editar(codigo){
      this.$router.push( {name: 'FormEditPerguntaSubmission', params: {cod: codigo, isSubmission: true}})
    },

    eliminar(codigo){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende eliminar a submissão com código ${codigo}? A operação é permanente.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            await axios.delete(host + 'quarentena/'+codigo);

            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Submissão removida com sucesso!",
              confirmButtonColor: "#009263",
            })
            .then(result => {
              if(result.isConfirmed) {
                this.$router.go(0)
              }
            })
            .catch(e => {throw new Error(e)}) 
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a submissão.");
        throw error;
      }
    },

  },
  computed: {
    getPending() {
        return this.divided[0]?.length
    },
    getAccepted() {
        return this.divided[1]?.length
    },
    getRejected() {
        return this.divided[2]?.length
    }
  },
  mounted() {
    this.getTemas()
    this.getQuestoesQuarentena()
    //console.log(`tab-${this.tab_sel}$${this.item_sel}$`)
    this.tab = `tab-${this.tab_sel}`
    this.expanded[this.tab_sel] = [{"cod": this.item_sel}]
    
  }
};
</script>

<style>
</style>
