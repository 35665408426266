import Vue from "vue";
import VueRouter from "vue-router";
import TheDashboard from "@/views/TheDashboard.vue";
import FormPergunta from "@/views/FormPergunta.vue";
import FormEditPergunta from "@/views/FormEditPergunta.vue";
import ListaPerguntas from "@/views/ListaPerguntas.vue";
import ListaPerguntasEscondidas from "@/views/ListaPerguntasEscondidas.vue";
import ListaQuarentena from "@/views/ListaQuarentena.vue";
import History from "@/views/History.vue";
import ThemesImages from "@/views/ThemesImages.vue";
import QuestoesMensais from "@/views/QuestoesMensais.vue";
import QuestoesHistory from "@/views/QuestoesHistory.vue";
import OrderTemas from "@/views/OrderTemas.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/dashboard" },
  {
    path: "/dashboard",
    name: "TheDashboard",
    component: TheDashboard,
  },
  {
    path: "/history",
    name: "History",
    props: true,
    component: History,
  },
  {
    path: "/themesimages",
    name: "ThemesImages",
    props: true,
    component: ThemesImages,
  },
  {
    path: "/monthlyquestions",
    name: "QuestoesMensais",
    props: true,
    component: QuestoesMensais,
  },
  {
    path: "/monthlyquestionshistory",
    name: "QuestoesHistory",
    props: true,
    component: QuestoesHistory,
  },
  {
    path: "/quarantine",
    name: "ListaQuarentena",
    props: true,
    component: ListaQuarentena,
  },
  {
    path: "/approved",
    name: "Approved",
    component: ListaPerguntas,
  },
  {
    path: "/hidden",
    name: "ListaPerguntasEscondidas",
    component: ListaPerguntasEscondidas,
  },
  {
    path: "/create",
    name: "FormPergunta",
    component: FormPergunta,
  },
  {
    path: "/orderthemes",
    name: "OrderTemas",
    component: OrderTemas,
  },
  {
    path: "/edit/submission/:cod",
    name: "FormEditPerguntaSubmission",
    component: FormEditPergunta,
    props: (route) => ({
      isSubmission: true,
      ...route.params
    })
  },
  {
    path: "/edit/question/:cod",
    name: "FormEditPerguntaQuestion",
    component: FormEditPergunta,
    props: (route) => ({
      isSubmission: false,
      ...route.params
    })
  },
  // NOT FOUND
  { path: "*", redirect: "/dashboard" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// caso token seja removido pelo backoffice
router.beforeEach((to, from, next) => {
  const auth = store.getters.isAuthenticated;
  const token = localStorage.getItem("token");
  if (!token && auth) store.dispatch("logout");
  else next();
});

export default router;
