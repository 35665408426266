<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Imagens dos Temas</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página é responsável por associar uma imagem a um tema da base de dados para ser visualizado na aplicação "Quero Resolver Questões de...".
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. No seletor de temas, estão listados todos os temas atualmente presentes na base de dados. Ao lado, aparece a imagem associada ao tema selecionado, caso exista.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. É possível selecionar uma imagem do computador e pré-visualizar a maneira que irá aparecer na aplicação "Quero Resolver Questões de...".
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. A alteração só será gravada se clicar no botão <v-btn small color="#009263" class="white--text">confirmar</v-btn>.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
                <v-row>
                    <v-col cols="6" sm="6" md="7" lg="8" xl="9" class="mb-auto mt-auto">
                        <v-combobox
                            color="#009263"
                            item-color="green"
                            outlined
                            flat
                            v-model="temaSelected"
                            :items="listaTemas"
                            label="Tema"
                            hide-details
                            @change="changePicture"
                            prepend-icon="mdi-format-list-bulleted"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="6" sm="6" md="5" lg="4" xl="3" align="center">
                        <h3 style="color:#009263" class="justify-center">Imagem Atual (Com Borda)</h3>
                        <v-btn
                            color="#009263" width="280" height="280" max-width="280" min-width="280" class="rounded-lg ml-auto mr-auto" 
                            style=" border-radius: 35px!important; cursor:default"
                            :ripple="false"
                            >
                        <v-img
                            :src="getCurrentImageLink"
                            :lazy-src="getCurrentImageLink"
                            aspect-ratio="1"
                            class="ml-auto mr-auto rounded-lg "
                            width="256" height="256" max-width="256" min-width="256"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="6" md="7" lg="8" xl="9" class="mb-auto mt-auto">
                        <v-file-input 
                            color="#009263"
                            outlined
                            flat
                            hide-details
                            label="Associar Nova Imagem"
                            item-color="green"
                            @change="uploaded"
                            v-model="uploadedImage"
                        >
                        </v-file-input>
                    </v-col>
                    <v-col cols="6" sm="6" md="5" lg="4" xl="3" align="center">
                        <h3 style="color:#009263" class="justify-center">Nova Imagem (Com Borda)</h3>
                        <v-btn
                            color="#009263" width="280" height="280" max-width="280" min-width="280" class="rounded-lg ml-auto mr-auto" 
                            style=" border-radius: 35px!important; cursor:default"
                            :ripple="false"
                            >
                        <v-img
                            :src="uploadedURL"
                            :lazy-src="uploadedURL"
                            aspect-ratio="1"
                            class="ml-auto mr-auto rounded-lg "
                            width="256" height="256" max-width="256" min-width="256"
                        >
                        </v-img>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-2 mr-2">
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="confirm"
                        :disabled="isDisabled"
                        >Confirmar</v-btn
                      >
                    </v-col>
                  </v-row>
            </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const currHostImages = require("@/config/hosts").currHostImages;
const hostImages = require("@/config/hosts").hostImagesSelector[currHostImages];
const Swal = require("sweetalert2");


export default {
  components:{
  },
  props: {

  },
  data() {
    return {
        loading: true,
        show:false,
        temasImagens: [],
        listaTemas: [],
        catalogoTemas: {},
        mapTemas: {},
        temaSelected: '',

        currentImageURL: null,
        uploadedURL: null,
        uploadedImage: null
    };
  },
  methods: {
    async getTemas(){
      const data = await axios.get(host + 'temas/agruparImagens')
      this.temasImagens = data.data
      this.listaTemas = this.temasImagens.map(tema => tema.tema).sort((a, b) => a.localeCompare(b))
      this.temaSelected = this.listaTemas[0]
      this.catalogoTemas = Object.fromEntries(this.temasImagens.map( tema => [ tema.tema, tema.img ] ))
      this.mapTemas = Object.fromEntries(this.temasImagens.map( tema => [ tema.tema, tema.codtema ] ))
      this.changePicture()
      this.loading = false
    },

    changePicture(){
        if(this.temaSelected != ''){
            this.currentImageURL = this.catalogoTemas[this.temaSelected]
        }
    },

    uploaded(){
        if(this.uploadedImage){
            this.uploadedURL= URL.createObjectURL(this.uploadedImage)
        }
        else this.uploadedURL = null
    },

    confirm(){
        let formData = new FormData()
        formData.append("files",this.uploadedImage)
        try {
        Swal.fire({
          title: `Tem a certeza que pretende atualizar a imagem do tema "${this.temaSelected}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(hostImages + 'upload', formData, {headers: {'Content-Type': `multipart/form-data; boundary=${formData._boundary}`}})
              .then(upload => {
                let codtema = this.mapTemas[this.temaSelected]
                let imagem = upload.data[0].url.replace(/^\/uploads\//g, '')
                axios.put(host + `tema_imagens/tema/${codtema}`, {imagem})
                    .then(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: "Imagem atualizada com sucesso!",
                            confirmButtonColor: "#009263",
                        })
                        .then(result => {
                            if(result.isConfirmed) {
                                this.$router.go(0)
                            }
                        })
                        .catch(e => {throw new Error(e)}) 
                    })
                    .catch(() => {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: 'Ocorreu um erro ao realizar a atualização da imagem.',
                            confirmButtonColor: "#009263",
                        })
                    })

              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: 'Ocorreu um erro ao realizar o upload da imagem.',
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a questão dos favoritos.");
        throw error;
      }
    }

  },
  computed: {
    getCurrentImageLink(){
        if(this.temaSelected === '' || this.currentImageURL ==='' || this.currentImageURL === null) return null
        else {
            return hostImages + "uploads/" + this.currentImageURL
        }
    },
    isDisabled(){
        return this.uploadedImage == null || this.uploadedImage == undefined
    }

  },
  mounted() {
    this.getTemas()
  }
};
</script>

<style>
</style>
