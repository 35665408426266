<template>
  <div class="grey lighten-3">
            <!--<v-btn text @click="download"
                      ><span>Mostrar Ajuda</span
                      ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>-->
            <tui-image-editor @undoStackChanged="undoStackChanged" ref="tuiImageEditor" @objectActivated="objectActivated" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
  </div>
</template>

<script>
//const axios = require("axios");
//const host = require("@/config/hosts").hostAPI;
//const moment = require("moment")
//const Swal = require("sweetalert2");
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
const hostImages = require("@/config/hosts").hostImages;
const hostImagesResol = require("@/config/hosts").hostImagesResol;
const currHostImages = require("@/config/hosts").currHostImages;
//import Preview from "../components/Preview.vue";

let locale_pt_PT = {
            Apply: 'Aplicar',
            Arrow: 'Seta',
            "Lock Aspect Ratio": 'Manter Proporção',
            "Arrow-2": 'Seta-2',
            "Arrow-3": 'Seta-3',
            Blend: 'Misturar',
            Blur: 'Embaçar',
            Bold: 'Negrito',
            Brightness: 'Luminosidade',
            Bubble: 'Bolha',
            Cancel: 'Cancelar',
            Center: 'Centro',
            Circle: 'Círculo',
            Color: 'Cor',
            "Color Filter": 'Filtro de Cor',
            Crop: 'Cortar',
            Custom: 'Customizado',
            "Custom icon": 'Ícone Customizado',
            Delete: 'Eliminar',
            "DeleteAll": 'Eliminar Todos',
            Distance: 'Distância',
            Download: 'Download',
            Draw: 'Desenhar',
            Hand: 'Mão',
            Emboss: 'Estampar',
            Fill: 'Preencher',
            Filter: 'Filtros',
            Flip: 'Virar',
            "Flip X": 'Virar X',
            "Flip Y": 'Virar Y',
            Free: 'Livre',
            Grayscale: 'Escala Cinza',
            Heart: 'Coração',
            Icon: 'Ícone',
            Invert: 'Inverter',
            Italic: 'Itálico',
            Left: 'Esquerda',
            Load: 'Carregar',
            "Load Mask Image": 'Inserir Imagem',
            Location: 'Localização',
            Mask: 'Imagem',
            Multiply: 'Multiplicar',
            Noise: 'Ruído',
            Pixelate: 'Pixelizar',
            Polygon: 'Polígono',
            Range: 'Ângulo',
            Rectangle: 'Retângulo',
            Redo: 'Refazer',
            "Remove White": 'Remover Branco',
            Reset: 'Repôr',
            Resize: 'Redimensionar',
            Right: 'Direita',
            Rotate: 'Rodar',
            Sepia: 'Sépia',
            Sepia2: 'Sépia 2',
            Shape: 'Forma',
            Sharpen: 'Alisar',
            Square: 'Quadrado',
            "Star-1": 'Estrela-1',
            "Star-2": 'Estrela-2',
            Straight: 'Reta',
            Stroke: 'Borda',
            Text: 'Texto',
            "Text size": "Tamanho da Letra",
            Threshold: 'Limite',
            Tint: 'Tingir',
            Triangle: 'Triângulo',
            Underline: 'Sublinhar',
            Undo: 'Reverter',
            Value: 'Valor',
            Width: 'Largura',
            Height: 'Altura',
            ZoomIn: 'Aumentar Zoom',
            ZoomOut: 'Diminuir Zoom',
            History: 'Histórico'
}

export default {
  props: {
    template: {
      type: Object,
      required: true
    },
    external_link: {
      type: String,
      required: false
    },
    external_link_type: {
      type: String,
      required: false
    }
  },
  components: {
    'tui-image-editor': ImageEditor,
  },
  data() {
    return {
        isMounted: false,
        useDefaultUI: true,
        options: {
        // for tui-image-editor component's "options" prop
            includeUI: {
                //loadImage: this.template,
                //{
                //  path: '/templates/template1.png',
                //  name: 'Template 1'
                //},
                uiSize: {
                  height: '100vh'
                },
                locale: locale_pt_PT, // key-value object with localization
                //initMenu: 'resize',
                menuBarPosition: 'bottom',
            },
            selectionStyle: {
              cornerColor: '#ff0000'
            },
            menuBarPosition: 'top',
            usageStatistics: false,
            //cssMaxWidth: 700,
            //cssMaxHeight: 500,
        },
        fontSelected: 'Open Sans',
        TUI_selectedFont: null,
        TUI_selectedItem: 'Arial'
    };
  },
  methods: {
    TUI_updateFontOnText(font){
        //console.log("TUI_updateFontOnText", font, this.TUI_selectedItem.id);

        if(font){
          this.TUI_selectedFont = font;
        }

        if(font && this.TUI_selectedItem){
          this.$refs.tuiImageEditor.invoke('changeTextStyle', this.TUI_selectedItem.id, {fontFamily: font});
          //this.TUI.changeTextStyle(this.TUI_selectedItem.id, {
          //  fontFamily: font
          //});
        }
    }, 

    TUI_updateFontSelected(layer) {
        //console.log("TUI_updateFontSelected", layer);

        if(layer.fontFamily){
            //console.log("123",document.querySelector('.font-selector').value)
            document.querySelector('.font-selector').value = layer.fontFamily
            this.TUI_selectedFont = layer.fontFamily;
        }
    },

    click(){
      this.options.includeUI.loadImage = {
        path: '',
        name: ''
      }
    },

    downloadImage(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },

    download(){
      let url = this.$refs.tuiImageEditor.invoke('toDataURL')
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          url = window.URL.createObjectURL(blob)
          window.open(url)
        })
      //this.downloadImage(this.$refs.tuiImageEditor.invoke('toDataURL'), "Template 1.png")
    },

    objectActivated(props){
      //console.log(props)
      this.TUI_selectedItem = props;
      this.TUI_updateFontSelected(props);
      //console.log('TUI_selectedItem', props);
    },

    changed(){
        //console.log(this.fontSelected)
    },

    undoStackChanged(){
      if(this.isMounted){
        //let name = this.$refs.tuiImageEditor.invoke('getImageName')
        //let name = '123.png'
        this.$emit('uploadPic')
        //console.log("changed stack")
      }
    },
    grabLinkResolucao() {
      let img = this.external_link;
      let name = img.split('/').pop()
      if(img.match(/^bckqr_r\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImagesResol['dev'] + img.replace(/^bckqr_r\//, '') + '.png'
        }
        else {
          img = hostImagesResol['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/propresolucao/${img.replace(".swf","")}.png`
          : "";
      }
      return {img,name};
    },
    grabLinkImagem() {
      let img = this.external_link;
      let name = img.split('/').pop()

      if(img.match(/^bckqr\/uploads\/.+$/)){
        if(currHostImages === 'dev'){
          img = hostImages['dev'] + img.replace(/^bckqr\//, '') + '.png'
        }
        else {
          img = hostImages['prod'] + img + '.png'
        }
      }
      else {
        img = img
          ? `https://www.hypatiamat.com/imagens/${img.replace(".swf", "")}.png`
          : "";
      }

      return {img,name};
    },
  },
  computed: {
   
  },
  mounted() {
      let fontArray = ["Arial", "Arial Black", "Caveat", "Comic Sans MS", "Courier New","Georgia1","Impact","Lobster Two", "Lucida Console","Luckiest Guy", "Open Sans","Pacifico", "Palatino Linotype","Press Start 2P", "Roboto", "Tahoma", "Tangerine", "Times New Roman","Tourney","Ultra", "Verdana","Symbol","Webdings","Wingdings"];

      let fontSelectHTML = '<select #fontselect class="form-select font-selector">';
      for (let i = 0; i < fontArray.length; i++) {
        let selected = '';
        if(i == 0){
          selected = 'selected';
        }
        fontSelectHTML += '<option style="font-family:'+fontArray[i]+';" value="'+fontArray[i]+'" '+selected+'>'+fontArray[i]+'</option>';
      }
      fontSelectHTML +=  '</select>';

      let textMenuAlign = document.querySelector('.tui-image-editor-menu-text .tie-text-align-button');
      textMenuAlign.insertAdjacentHTML('afterbegin', fontSelectHTML);
      //this.$refs.tuiImageEditor.ui.text.colorPickerInputBox.defaultValue = "#ff0000"

      //this.$refs.tuiImageEditor.invoke('resizeCanvasDimension', {dimension: {height: '100vh'}})
      let button = document.querySelector('.tie-mask-apply')
      button.remove()
      document.querySelector('.font-selector').addEventListener('change', () =>
      this.TUI_updateFontOnText(document.querySelector('.font-selector').value));
      if(this.external_link && this.external_link!==''){
        let {img, name} = this.external_link_type === 'imagem' ? this.grabLinkImagem() : this.grabLinkResolucao()
        //console.log(img,name)
        this.$refs.tuiImageEditor.invoke('loadImageFromURL', img, name)
          .then(() => {
            if (this.$refs.tuiImageEditor.editorInstance) {
              this.$refs.tuiImageEditor.editorInstance.ui.activeMenuEvent()
            }
          });
      }
      else if(this.template.path && this.template.name!=='template0.png' && this.template.name!=='i_template0.png') {
        //console.log( this.$refs.tuiImageEditor.invoke('getCanvasSize'))
        this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.template.path, this.template.name)
          .then(() => {
            if (this.$refs.tuiImageEditor.editorInstance) {
              this.$refs.tuiImageEditor.editorInstance.ui.activeMenuEvent()
            }
          });
      }
      //console.log("MY TEMPLATE:", this.template)
      this.isMounted = true
      //-------------------------------------
      
  }
};
</script>

<style>
/* #inspire > div > main > div > div.tui-image-editor-container.bottom > div.tui-image-editor-main-container{
  background-color: whitesmoke !important
  } */

select {
    background-color: white;
    text-align-last: center;
    padding: 15px;
    vertical-align: top;
    text-align: center;
    -webkit-appearance: auto;
}
select:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 50%;
    margin-top: -3px;
    height: 0;
    width: 0;
    border-top: 6px solid #f99300;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    pointer-events: none;
}

</style>
