<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Questões Propostas</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página mostra todas as questões <b>pendentes</b> que foram submetidas para aprovação.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. De modo a facilitar a navegação, todas as submissões efetuadas por si estão escondidas, uma vez que não as pode rejeitar nem aceitar. Para as mostrar, basta clicar no quadrado abaixo e já aparecerão. Para além disso, é possível ordenar por ordem alfabética qualquer uma das colunas da tabela através de um clique nas mesmas. Cada clique sucessivo alterna entre ordem natural, ascendente e descendente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada linha da tabela tem um botão <v-icon>mdi-chevron-down</v-icon> que abre o pré-visualizador da questão. Após isso, tem também um botão que permite aceitar a submissão <v-btn class="white--text" small color="#009263"><v-icon medium>mdi-check</v-icon></v-btn>, adicionando-a à base de dados de questões (<b style="color:#009263">Questões Aprovadas</b>), um para proceder à edição <v-btn class="white--text" small color="#ffa500"><v-icon medium >mdi-note-edit-outline</v-icon></v-btn> e um para a rejeitar
                    <v-btn class="white--text" small color="#d33"><v-icon medium >mdi-close</v-icon></v-btn> onde é pedido um comentário que descreve a razão da rejeição.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Caso seja realizada a edição da submissão, é gerada uma nova e a atual será descartada.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. Caso a submissão seja rejeitada, ela não é descartada. O professor que a submeteu será notificado que efetivamente foi rejeitada e terá a chance de a submeter de novo após corrigir os campos necessários, que idealmente estarão descritos no comentário de rejeição.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-checkbox
                    align="center"
                    v-model="mineToggle"
                    color="#009263"
                    hide-details
                    mandatory
                    label="Mostrar as minhas submissões"
                  ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <!-- Tabela -->
          <v-container v-if="loading">
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
          </v-container>
          <v-container v-else>
            <v-data-table
              no-data-text="Não existem Dados"
              :footer-props="{
                'items-per-page-text': 'Mostrar',
                'items-per-page-options': [10, 25, 50],
                'items-per-page-all-text': 'Todos',
              }"
              dense
              show-expand
              :expanded.sync="expanded"
              :items-per-page=25
              :headers="headers" 
              :items="getFilteredItems"
              item-key="cod"
              class="elevation-1"
              color="#009263"
            >
            <template v-slot:[`footer.page-text`]="props">
              {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <Preview :pergunta="item"/>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="aceitar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-8" color="#009263">
                      <v-icon >
                       mdi-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Aceitar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="editar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-2" color="#ffa500">
                      <v-icon >
                       mdi-note-edit-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="rejeitar(item)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-2" color="#d33">
                      <v-icon >
                       mdi-close
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Rejeitar</span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:[`item.questao`]="{ item }">
                <span v-html="item.questao"></span>
            </template>
            <template v-slot:[`item.tipo`]="{ item }">
                {{ getTipo(item.tipo) }}
            </template>
            <template v-slot:[`item.tema`]="{ item }">
                {{ getTema(item.tema) }}
            </template>
            <template v-slot:[`item.subtema`]="{ item }">
                {{ getSubtema(item.tema, item.subtema) }}
            </template>
            <template v-slot:[`item.estado.data_submissao`]="{ item }">
                {{ formatDate(item.estado.data_submissao) }}
            </template>
            <template v-slot:[`item.estado.data_alteracao`]="{ item }">
                {{ formatDate(item.estado.data_alteracao) }}
            </template>
            <!--
            <template v-slot:[`item.estado.valor`]="{ item }">
                {{ getEstado(item.estado.valor) }}
            </template>
            -->
            </v-data-table>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require("moment")
const Swal = require("sweetalert2");

import Preview from "../components/Preview.vue";

export default {
  components:{
    Preview
  },
  props: {
    item_sel: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      loading: true,
      show:false,
      results: [], 
      exames: [],
      temas: [],
      catalogoTemas: {},
      temasFiltro: [],
      subtemasFiltro: [],
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'7%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'10%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'20%'},
        //{ text: "Exame", value: "idexame", class: "subtitle-1" ,width:'20%'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'10%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'15%'},
        //{ text: "Nível", value: "niveldificuldade", class: "subtitle-1" },
        //{ text: "Ano", value: "ano", class: "subtitle-1"}
        { text: "Submissão Por", value: "estado.submissao_por", class: "subtitle-1"},
        { text: "Data de Submissão", value: "estado.data_submissao", class: "subtitle-1"},
        { text: "Última Alteração", value: "estado.data_alteracao", class: "subtitle-1"},
      ],
      exameValue: '',
      temaSelected: '',
      subtemaSelected: '',
      anoSel: '',
      nivelSel: '',
      questao: '',
      codigo: '',
      tipoSel: '',
      anoRules: [(v) => v && v > 0 && v < 10],
      nivelRules: [(v) => v && v > 0 && v < 5],
      mineToggle: false,

      expanded: []
    };
  },
  methods: {
    getTema(tema){
      return this.temas[tema] ? this.temas[tema].tema : ''
    },

    getSubtema(tema,subtema){
      return this.temas[tema] ? this.temas[tema].subtemas[subtema] : ''
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    changeSubtemas(){
      this.subtemaSelected = ''
      let tema = this.catalogoTemas[this.temaSelected]
      if(this.temaSelected != null) this.subtemasFiltro = Object.entries(this.temas[tema].subtemas).map(e => e[1])
      else this.subtemasFiltro = []
    },

    wipeSubtema(){
      this.temaSelected = ''
      this.subtemaSelected = ''
    },

    getSubtemaNr(tema){
      let subtemas = this.temas[tema].subtemas 
      if(this.temaSelected != '' && this.subtemaSelected != ''&& this.temaSelected != null && this.subtemaSelected != null) {
          for (var [key, value] of Object.entries(subtemas)) {
              if(this.subtemaSelected == value) return key
          }
      }
      return ''
    },

    async getQuestoesQuarentena() {
      const data = await axios.get(host + 'quarentena?_limit=-1')
      this.results = data.data
      this.loading = false
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    async getExames(){
      const data = await axios.get(host + 'perguntas/exames')
      this.exames = data.data
    }, 

    getEstado(valor){
        switch(valor){
            case 0:
                return 'Pendente'
            case 1:
                return 'Aceite'
            case 2:
                return 'Recusado'
        }
    },

    formatDate(date){
      return moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY, HH:mm:ss') : 'Indisponível'
    },

    aceitar(codigo){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende aceitar submissão da questão com código ${codigo}? Esta operação é permanente.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a operação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.put(host + 'quarentena/aceitar/'+codigo, {})
              .then(() => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Questão aprovada com sucesso!",
                  confirmButtonColor: "#009263",
                })
                .then(result => {
                  if(result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
                .catch(e => {throw new Error(e)}) 
              })
              .catch(error => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível aceitar a submissão.");
        throw error;
      }
    },

    editar(codigo){
      this.$router.push( {name: 'FormEditPerguntaSubmission', params: {cod: codigo, isSubmission: true}})
    },

    rejeitar(item){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende rejeitar a submissão de ${item.estado.submissao_por} com código ${item.cod}?`,
          icon: "warning",
          input: 'textarea',
          inputPlaceholder: 'Adicionar um comentário...',
          customClass:{
            'border-color': '#009263 !important'
          },
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then( result => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.put(host + 'quarentena/recusar/'+ item.cod, {comentario: result.value})
              .then(() => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Questão recusada com sucesso!",
                  confirmButtonColor: "#009263",
                })
                .then(result => {
                  if(result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
                .catch(e => {throw new Error(e)}) 
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: 'Ocorreu um erro.',
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a submissão.");
        throw error;
      }
    }

  },
  computed: {
    getFilteredItems() {
      const userId = this.$store.getters.getUserId;
      let filtered = [...this.results]
              .filter(questao => {
                return questao.cod.toLowerCase().includes(this.codigo.toLowerCase()) &&
                  questao.questao.toLowerCase().includes(this.questao.toLowerCase()) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null ? questao.tema.toLowerCase() == this.catalogoTemas[this.temaSelected] : questao.tema.toLowerCase().includes('')) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null && this.subtemaSelected != '' && this.subtemaSelected != null ? questao.subtema.toLowerCase() == this.getSubtemaNr(questao.tema) : questao.subtema.toLowerCase().includes('')) &&
                  this.getTipo(questao.tipo).toLowerCase().includes(this.tipoSel.toLowerCase()) &&
                  questao.idexame.toLowerCase().includes(this.exameValue.toLowerCase())  && 
                  questao.ano.toString().toLowerCase().includes(this.anoSel.toString().toLowerCase()) &&
                  questao.niveldificuldade.toString().toLowerCase().includes(this.nivelSel.toString().toLowerCase()) &&
                  (this.mineToggle ? true : userId !== questao.estado.submissao_por)
              })
      return filtered
    }
  },
  mounted() {
    this.getTemas()
    this.getQuestoesQuarentena()
    this.expanded=[{"cod": this.item_sel}]
    //this.getExames()
  }
};
</script>

<style>
.swal2-textarea{
  border-color: #009263 !important
}
table th:not(:first-child) + th { border-left:1px solid #dddddd; }
table td:not(:first-child) + td { border-left:1px solid #dddddd; }
</style>
