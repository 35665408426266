<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-container>
            <v-card-title class="justify-center green--text">
              <h2>
                Dashboard
              </h2>
            </v-card-title>
            <!-- Ajuda -->
            <center>
              <v-btn v-if="!show" text @click="show = !show"
                ><span>Mostrar Ajuda</span
                ><v-icon color="#009263"> mdi-help-circle </v-icon>
              </v-btn>
              <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
            </center>
            <v-slide-y-transition>
              <v-card
                v-show="show"
                class="elevation-6 pa-3"
                style="border: 2px solid green !important;"
                color="grey lighten-3"
              >
                <v-row>
                  <v-col cols="12">
                    <span>
                      1. Esta página serve para visualizar graficamente informação relativa às questões submetidas na plataforma.
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <span>
                      2. Para além dos gráficos existe uma secção de notificações, que podem ser de dois tipos: 
                      <br>
                      <span class="ml-4">
                        2.1. uma submissão efetuada por si foi aceite ou rejeitada;
                      </span>
                      <br>
                      <span class="ml-4">
                        2.2. foi efetuada uma nova submissão por outro utilizador.
                      </span>
                    </span>
                  </v-col>
                  <v-col cols="12">
                    3. Em qualquer uma das notificações existe o botão <v-btn small rounded dark color="#009263"><v-icon small dark>mdi-arrow-right-thick</v-icon></v-btn> que redireciona para o local apropriado, isto é, ao <b style="color:#009263">Histórico de Submissões</b> ou às <b style="color:#009263">Questões Propostas</b> consoante o tipo da notificação.
                  </v-col>
                  <v-col cols="12">
                    4. A notificação só é eliminada quando, simultaneamente, o botão associado é clicado e a notificação é do tipo 2.1. Se for do tipo 2.2, ela mantém-se até a submissão associada ser aceite ou rejeitada.
                  </v-col>
                </v-row>
              </v-card>
            </v-slide-y-transition>
            <br v-if="show" />
              <div v-if="loading">
                <v-container>
                  <center>
                    <v-img
                      :src="require('@/assets/loading.gif')"
                      width="150px"
                      heigth="150px"
                    >
                    </v-img>
                  </center>
                </v-container>
              </div>
              <div v-else>
              <v-row class="my-4">
                <v-col cols="6" sm="12" md="12" lg="6" xl="6">
                  <v-card height='100%'>
                    <highcharts ref="highcharts" :options="submissoes_professor" /> 
                  </v-card>
                </v-col>
                <v-col cols="6" sm="12" md="12" lg="6" xl="6">
                  <v-card>
                    <highcharts ref="highcharts" :options="submissoes_total_options" />
                  </v-card>
                </v-col>
              </v-row>
              <v-list v-if="notificacoes.length > 0">
<!--                 <v-btn
                  class="ml-4 mb-5 white--text"
                  large
                  @click="wipeNotificacoes"
                  :disabled="notificacoes.length===0"
                  color="#009263"
                  ><v-icon dark>
                    mdi-trash-can-outline
                  </v-icon>Limpar Notificações</v-btn
                > -->
                <template v-for="item in notificacoes">
                  <v-list-item :key="item.id">
                    <v-list-item-content >
                      <v-list-item-title>
                        <b v-if="item.tipo===0"
                          ><span>Minha Submissão (Código <span style="color:green;">{{item.cod}}</span>)</span></b
                        >
                        <b v-else-if="item.tipo===1"
                          ><span>Nova Submissão (Código <span style="color:green;">{{item.cod}}</span>)</span></b
                        >
                      </v-list-item-title>
                      <b
                        ><span style="color:green;">Estado: </span>
                        <span v-if="item.valor===1"><v-icon dense color="#009263">mdi-check</v-icon> Aceite</span>
                        <span v-else-if="item.valor===2"><v-icon dense color="red">mdi-close</v-icon> Rejeitada</span>
                        <span v-else><v-icon dense>mdi-history</v-icon> Pendente</span>
                      </b>
                      <b v-if="item.tipo===0"
                        ><span style="color:green">Decisão Por: </span>
                        {{item.decisao_por}}
                      </b>
                      <b v-else-if="item.tipo===1"
                        ><span style="color:green">Submissão Por: </span>
                        {{item.submissao_por}}
                      </b>
                      <b
                        ><span style="color:green;">Data: </span>
                        {{formatDate(item.data_criacao)}}
                      </b>
                    </v-list-item-content>
                    <v-list-item-action>
                     
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn
                        large
                        @click="navigateFromNotification(item)"
                        rounded
                        dark
                        color="#009263"
                        ><v-icon dark>
                        mdi-arrow-right-thick
                      </v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`div${item.id}`"></v-divider>
                </template>
              </v-list>
              <v-container v-else>
                <h2 style="text-align: center; color:#666666;">
                  Não existem notificações de momento.
                </h2>
              </v-container>
              </div>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const moment = require("moment")
const Swal = require("sweetalert2");
import {Chart} from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart 
  },
  async created() {
    this.loading = true
    await this.getNotificacoes()
    await this.getStatistics()
    this.loading = false
  },
  data() {
    return {
      show: false,
      user: null,
      loading: false,

      notificacoes: [],

      submissoes_total_options: {
        colors: ['#7CB5EC','#64E572', '#ED561B'],
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: 'Estado Atual das Minhas Submissões',
            align: 'center',
            verticalAlign: 'top',
            y: 60
        },
        tooltip: {
            pointFormat: 'Total: <b>{point.z}</b></br>{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            enabled: false,
            point: {
                valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '110%'
            }
        },
        series: [{
            type: 'pie',
            name: 'Percentagem',
            innerSize: '50%',
            data: []
        }]
      },
      submissoes_professor: {
          chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
          },
          title: {
              text: 'Distribuição de Submissões Efetuadas'
          },
          tooltip: {
              pointFormat: 'Total: <b>{point.z}</b></br>{series.name}: <b>{point.y:.1f}%</b>'
          },
          accessibility: {
              point: {
                  valueSuffix: '%'
              }
          },
          credits: {
              enabled: false
          },
          plotOptions: {
              pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: true,
                      format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
              }
          },
          series: [{
              name: 'Percentagem',
              colorByPoint: true,
              data: []
          }]
      }
    }
  },
  computed: {
    
  },
  methods: {
    formatDate(date){
      return moment(date).isValid() ? moment(date).locale('pt').format('DD/MM/YYYY, HH:mm:ss') : 'Indisponível'
    },
    navigateFromNotification(item){
      let item_sel = item.cod
      if(item.tipo===0){
          let tab_sel = `${item.valor + 1}`
          // eliminar notificacao
          axios.delete(host + 'notificacoes/'+ item.id)  
            .then(() => {
              this.$router.push({
                  name: 'History', 
                  params: { tab_sel, item_sel }
              });
            })
            .catch(e => console.log(e))
      }
      else if(item.tipo ===1){
        this.$router.push({
              name: 'ListaQuarentena', 
              params: { item_sel }
           });
      }
    },
    async wipeNotificacoes(){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende eliminar todas as notificações?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            await axios.delete(host + 'notificacoes/utilizador');

            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Notificações removidas com sucesso!",
              confirmButtonColor: "#009263",
            })
            .then(result => {
              if(result.isConfirmed) {
                this.$router.go(0)
              }
            })
            .catch(e => {throw new Error(e)}) 
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar as notificações.");
        throw error;
      }
    },

    async getNotificacoes(){
      //const data = await axios.get(host + 'notificacoes?_limit=-1&_sort=data_criacao:desc&submissao_por=' + this.$store.getters.getUserId)
      const data = await axios.get(host + 'notificacoes/utilizador')
      this.notificacoes = data.data
    },

    async getStatistics(){
      const data = await axios.get(host + 'quarentena/estatisticas')
      let states = ['Pendentes', 'Aceites', 'Rejeitadas']
      let colors = ['#7CB5EC', '#009263', '#DD3333']
      //let total = data.data.total_submissoes.map(obj => [states[obj.estado], obj.percentagem])
      let total = data.data.total_submissoes.map(obj => ({name: states[obj.estado], color: colors[obj.estado], y: obj.percentagem, z: obj.total}))
      let profs = data.data.submissoes_professor.map(obj => ({name: obj.professor, y: obj.percentagem * 100, z: obj.total}))
      this.submissoes_total_options.series[0].data = total
      this.submissoes_professor.series[0].data = profs
    }
  },
};
</script>

<style>

</style>
