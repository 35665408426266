<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Ordenar Temas</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página é responsável por ordenar os temas (e respetivas imagens) na aplicação "Quero Resolver Questões de...".
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. A lista abaixo representa todos os temas existentes atualmente na base de dados e estão ordenados de acordo com a ordem gravada mais recente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Para alterar a posição de um dos elementos da lista, basta clicar e segurar o botão do lado esquerdo do rato e arrastá-lo para o local pretendido.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Caso necessite de ordenar alfabeticamente os temas, poderá utilizar os botões <v-btn class="white--text" color="#009263"><v-icon >mdi-arrow-up</v-icon> </v-btn> e <v-btn class="white--text" color="#009263"><v-icon >mdi-arrow-down</v-icon> </v-btn> para o realizar de forma crescente ou decrescente, respetivamente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. A nova ordenação só será gravada se clicar no botão <v-btn small color="#009263" class="white--text">confirmar</v-btn>.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="crescente" v-bind="attrs" v-on="on" large class="white--text mb-4 ml-8" color="#009263">
                      <v-icon >
                        mdi-arrow-up
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Ordem Alfabética Crescente </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="decrescente" v-bind="attrs" v-on="on" large class="white--text mb-4 ml-2" color="#009263">
                      <v-icon >
                        mdi-arrow-down
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Ordem Alfabética Decrescente </span>
                </v-tooltip>
                <v-list class="py-0 ml-8 mr-8">
                    <draggable
                    v-model="temasOrder"
                    @change="changed"
                    :forceFallback="true"
                    >
                    <template v-for="(tema, index) in temasOrder">
                        <v-list-item color="#009263" @click.stop class="py-0 mt-2 v-list-item--active" :key="tema[0]" ref="item">
                        <v-list-item-avatar color="#009263" size="42" class="px-1">
                            <span class="white--text headline">{{ index + 1 }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                            class="px-3 font-weight-bold"
                            align="left"
                            v-text="tema[1]"
                            ></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </template>
                    </draggable>
                </v-list>
                                <v-row class="mt-2 mr-2">
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="confirm"
                        :disabled="isDisabled"
                        >Confirmar</v-btn
                      >
                    </v-col>
                  </v-row>
            </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const Swal = require("sweetalert2");
import draggable from "vuedraggable";

export default {
  components:{
    draggable
  },
  props: {

  },
  data() {
    return {
        loading: true,
        show:false,
        temasOrder: []
    };
  },
  methods: {
    async getTemas(){
      //const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      const data = await axios.get(host + 'temas/agruparOrdem')
      //this.temas = data.data
      this.temasOrder = data.data
      //this.crescente()
      this.loading = false
    },

    changed(){
        console.log(this.temasOrder)
    },

    crescente(){
        this.temasOrder.sort((a, b) => a[1].localeCompare(b[1]))
        console.log(this.temasOrder)
    },

    decrescente(){
        this.temasOrder.sort((a, b) => b[1].localeCompare(a[1]))
        console.log(this.temasOrder)
    },

    confirm(){
        try {
        Swal.fire({
          title: `Tem a certeza que pretende gravar a nova ordenação dos temas?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a verificação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(host + 'tema_ordem', [...this.temasOrder].map(entry => parseInt(entry[0])))
              .then(() => {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: "Ordem dos temas atualizada com sucesso!",
                        confirmButtonColor: "#009263",
                    })
                    .then(result => {
                        if(result.isConfirmed) {
                            this.$router.go(0)
                        }
                    })
                    .catch(e => {throw new Error(e)}) 

              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: 'Ocorreu um erro ao realizar o upload da imagem.',
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível eliminar a questão dos favoritos.");
        throw error;
      }
    }

  },
  computed: {
    isDisabled(){
        return false
    },

  },
  mounted() {
    this.getTemas()
  },
};
</script>

<style>
</style>
