<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Questões Escondidas</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página apresenta todas as questões escondidas que se encontram na base de dados sob a forma de tabela. 
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. De modo a facilitar a navegação, é possível ordenar por ordem alfabética qualquer uma das colunas da tabela através de um clique nas mesmas. Cada clique sucessivo alterna entre ordem natural, ascendente e descendente.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada linha da tabela tem um botão <v-icon>mdi-chevron-down</v-icon> que abre o pré-visualizador da questão. Após isso, tem também um botão <v-btn class="white--text" small color="#009263"><v-icon medium>mdi-eye</v-icon></v-btn> que permite expor novamente a questão para a aplicação <b>Quero Resolver Questões de...</b>
                     .
                  </span>
                </v-col>

              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
          <!-- Tabela -->
          <v-container v-if="loading">
            <center>
              <v-img
                :src="require('@/assets/loading.gif')"
                width="150px"
                heigth="150px"
              >
              </v-img>
            </center>
          </v-container>
          <v-container v-else>
            <v-data-table
              no-data-text="Não existem Dados"
              :footer-props="{
                'items-per-page-text': 'Mostrar',
                'items-per-page-options': [10, 25, 50],
                'items-per-page-all-text': 'Todos',
              }"
              dense
              show-expand
              :items-per-page=25
              :headers="headers" 
              :items="results"
              item-key="cod"
              class="elevation-1"
              color="#009263"
            >
            <template v-slot:[`footer.page-text`]="props">
              {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <Preview :pergunta="item"/>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  @click="expor(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-8" color="rgb(0, 146, 99)">
                      <v-icon >
                      mdi-eye
                      </v-icon>
                      </v-btn>
                  </template>
                  <span>Expor</span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:[`item.questao`]="{ item }">
                <span v-html="item.questao"></span>
            </template>
            <template v-slot:[`item.tipo`]="{ item }">
                {{getTipo(item.tipo)}}
            </template>
            <template v-slot:[`item.idexame`]="{ item }">
                {{getExame(item.idexame)}}
            </template>
            <template v-slot:[`item.tema`]="{ item }">
                {{ getTema(item.tema) }}
            </template>
            <template v-slot:[`item.subtema`]="{ item }">
                {{ getSubtema(item.tema, item.subtema) }}
            </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
const Swal = require("sweetalert2");
import Preview from "../components/Preview.vue";

export default {
  components:{
    Preview
  },
  data() {
    return {
      loading: true,
      show: false,
      results: [], 
      temas: [],
      catalogoTemas: {},
      tipos: ['Escolha Múltipla', 'Resposta Aberta', 'Verdadeiro ou Falso', 'Simetria'],
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'7%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'10%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'20%'},
        { text: "Exame", value: "idexame", class: "subtitle-1" ,width:'20%', align:'center'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'10%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'15%'},
        { text: "Nível", value: "niveldificuldade", class: "subtitle-1" },
        { text: "Ano", value: "ano", class: "subtitle-1"}
      ],
    };
  },
  methods: {
    getTema(tema){
      return this.temas[tema]?.tema 
    },

    getSubtema(tema,subtema){
      return this.temas[tema]?.subtemas[subtema]
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    async getPerguntasEscondidas() {
      const data = await axios.get(host + 'perguntas/invisiveis')
      this.results = data.data
      this.loading = false
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
      this.temasFiltro.sort((a, b) => a.localeCompare(b))
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    getExame(idexame){
      return idexame === '' ? '-' : idexame 
    },

    expor(codigo){
      try {
        Swal.fire({
          title: `Tem a certeza que pretende expor novamente na plataforma "Quero Resolver Questões de..." a questão com código ${codigo}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a operação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(host + 'perguntas_escondidas/expor/'+ codigo)
              .then( () => {
                  Swal.close();
                  Swal.fire({
                    icon: "success",
                    title: "Questão exposta com sucesso!",
                    confirmButtonColor: "#009263",
                  })
                   .then(result => {
                    if(result.isConfirmed) {
                      this.$router.go(0)
                    }
                  }) 
                  .catch(e => {throw new Error(e)}) 
              })
              .catch( () => {
                 Swal.close();
                 Swal.fire({
                   icon: "error",
                   title: "A pergunta já se encontra exposta.",
                   confirmButtonColor: "#009263",
                 })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível expor a questão.");
        throw error;
      }
    },

  },
  computed: {
  },
  mounted() {
    this.getTemas()
    this.getPerguntasEscondidas()
  }
};
</script>

<style>
table th:not(:first-child) + th { border-left:1px solid #dddddd; }
table td:not(:first-child) + td { border-left:1px solid #dddddd; }
</style>
