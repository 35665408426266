<template>
  <div class="grey lighten-3">
    <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <v-card class="pa-5">
          <v-card-title class="justify-center green--text">
            <h2>Questões Mensais</h2>
          </v-card-title>
           <!-- Ajuda -->
          <center>
            <v-btn v-if="!show" text @click="show = !show"
              ><span>Mostrar Ajuda</span
              ><v-icon color="#009263"> mdi-help-circle </v-icon>
            </v-btn>
            <v-btn v-else text @click="show = !show">Esconder Ajuda</v-btn>
          </center>
          <v-slide-y-transition>
            <v-card
              v-show="show"
              class="elevation-6 pa-3"
              style="border: 2px solid green !important;"
              color="grey lighten-3"
            >
              <v-row class="justify-center">
                <v-col cols="12">
                  <span>
                    1. Esta página contém o conjunto de questões selecionadas para o mês atual da aplicação "Quero Resolver Questões de...".
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    2. Existem atualmente 4 categorias dedicadas para os vários anos do Ensino Básico e cada uma delas é representada por um botão na secção abaixo: 
                    <br/>
                    <span class="ml-4">
                      2.1. <b>Nível 1</b> - 1º e 2º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.2. <b>Nível 2</b> - 3º e 4º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.3. <b>Nível 3</b> - 5º e 6º Ano
                      <br/>
                    </span>
                    <span class="ml-4">
                      2.4. <b>Nível 4</b> - 7º, 8º e 9º anos
                      <br/>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    3. Cada categoria pode ter uma número variável de questões, desde 0 até um número arbitrário.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    4. Abaixo das categorias, existe uma tabela de questões com vários filtros para facilitar a sua navegação. 
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    5. É possível pré-visualizar cada questão através do botão <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn> associado em cada linha da tabela, tal como nas restantes páginas da aplicação.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    6. Para adicionar a questão à <b>categoria selecionada</b>, clique no botão <v-btn color="#009263" class="white--text" small><v-icon>mdi-plus</v-icon></v-btn> abaixo do pré-visualizador.
                  </span>
                </v-col>
                  <v-col cols="12">
                  <span>
                    7. Todas as questões adicionadas à categoria atual aparecerão na aba <b style="color:#009263">Questões Selecionadas</b> e estarão representadas da seguinte forma: <v-chip  class="ma-2 white--text" close color="#009263">Código</v-chip>.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    8. Para remover a questão clique no botão <v-btn small icon><v-icon small>mdi-close-circle</v-icon></v-btn> presente na representação mencionada acima.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    9. Cada questão só pode ser adicionada <b>uma vez</b> na mesma categoria mas pode ser repetida nas restantes, caso seja necessário.
                  </span>
                </v-col>
                <v-col cols="12">
                  <span>
                    10. As alterações realizadas só serão guardadas se clicar no botão <v-btn small color="#009263" class="white--text">guardar</v-btn> e podem ser feitas as vezes que forem necessárias para o mês atual.
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-y-transition>
          <br />
            <div v-if="loading">
                <center>
                <v-img
                    :src="require('@/assets/loading.gif')"
                    width="150px"
                    heigth="150px"
                >
                </v-img>
                </center>
            </div>
            <div v-else>
              <h3 style="color:#009263" class="ml-2 mt-2 mb-2">Categorias</h3>
              <v-btn-toggle
                v-model="categoria"
                mandatory
                @change="changeCategoria"
                class="d-flex align-content-start flex-wrap"
                color="#009263"
              >
                <v-btn
                      large
                      class="px-2 flex-grow-1 rounded-lg ml-auto mr-auto" 
                      v-for="(item,idx) in categorias"
                      :key="idx"
                      >
                      {{item}}
                </v-btn>
              </v-btn-toggle>
<!--               <div class="text-center"> -->
              <h3 style="color:#009263" class="ml-2 mt-2">Questões Selecionadas</h3>
              <div v-if="!isEmptyChips" class="mt-2">
                <v-chip
                  large
                  v-for="(item,i) in getChips"
                  :key="i"
                  class="ma-2 white--text"
                  @click:close="removeChip(i)"
                  close
                  color="#009263"
                >
                  {{item}}
                </v-chip>
              </div>
              <div v-else>
                <h4 class="ml-2 mt-2">Atualmente não tem existe nenhuma questão nesta categoria.</h4>
              </div>
              <h3 style="color:#009263" class="ml-2 mt-2">Adicionar Questões</h3>
              <!--Filtros -->
              <center>
                <v-container >
                  <v-row>
                  <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                      <v-textarea
                      color="#009263"
                      rows="1"
                      auto-grow
                      hide-details
                      clearable
                      @input="codigo = $event !== null ? $event : ``"
                      outlined
                      type="text"
                      label="Código"
                      prepend-icon="mdi-code-tags"
                      v-model="codigo">
                    </v-textarea>
                  </v-col>
                  
                  <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                    <v-select
                        color="#009263"
                        item-color="green"
                        outlined
                        clearable
                        flat
                        v-model="tipoSel"
                        @input="tipoSel = $event !== null ? $event : ``"
                        @change="changeTipo()"
                        :items="tipos"
                        label="Tipo"
                        hide-details
                        prepend-icon="mdi-clipboard-list-outline"
                      ></v-select>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                          <v-select
                            color="#009263"
                            item-color="green"
                            outlined
                            flat
                            clearable
                            :disabled="tipoSel!=='Resposta Aberta'"
                            v-model="subtipoSel"
                            @input="subtipoSel = $event !== null ? $event : ``"
                            @change="changeSubtipo()"
                            :items="subtipos"
                            label="Subtipo"
                            hide-details
                            prepend-icon="mdi-flag-variant-outline"
                          ></v-select>
                        </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          color="#009263"
                          outlined
                          hide-details
                          clearable
                          v-model="nivelSel"
                          @input="nivelSel = $event !== null ? $event : ``"
                          type="number"
                          label="Nível"
                          min="1"
                          max="4"
                          prepend-icon="mdi-head-cog-outline"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                      <v-text-field
                          color="#009263"
                          outlined
                          hide-details
                          clearable
                          v-model="anoSel"
                          @input="anoSel = $event !== null ? $event : ``"
                          type="number"
                          label="Ano"
                          min="1"
                          max="9"
                          prepend-icon="mdi-ruler-square-compass"
                        ></v-text-field>
                    </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-combobox
                        color="#009263"
                        item-color="green"
                        hide-details
                        clearable
                        @input="exameValue = $event !== null ? $event : ``"
                        outlined
                        v-model="exameValue"
                        :items="exames"
                        flat
                        label="Exame"
                        prepend-icon="mdi-school-outline"
                      ></v-combobox>
                    </v-col>
                  </v-row>     
                  <v-row>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-combobox
                        color="#009263"
                        item-color="green"
                        outlined
                        clearable
                        flat
                        v-model="temaSelected"
                        @click:clear="wipeSubtema"
                        :items="temasFiltro"
                        label="Tema"
                        hide-details
                        @change="changeSubtemas"
                        prepend-icon="mdi-format-list-bulleted"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-combobox
                        color="#009263"
                        item-color="green"
                        v-model="subtemaSelected"
                        outlined
                        clearable
                        hide-details
                        flat
                        :items="subtemasFiltro"
                        label="Subtema"
                        prepend-icon="mdi-format-list-text">
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <v-textarea
                      color="#009263"
                      rows="1"
                      auto-grow
                      clearable
                      @input="questao = $event !== null ? $event : ``"
                      hide-details
                      outlined
                      type="text"
                      label="Questão"
                      prepend-icon="mdi-clipboard-edit-outline"
                      v-model="questao">
                    </v-textarea>
                    
                  </v-col>
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                      <v-text-field
                          color="#009263"
                          outlined
                          hide-details
                          clearable
                          v-model="respostaSel"
                          @input="respostaSel = $event !== null ? $event : ``"
                          label="Respostas 1 a 6"
                          prepend-icon="mdi-pencil"
                        ></v-text-field>
                  </v-col>
                </v-row>
                </v-container>
              </center>
              <v-data-table
                  no-data-text="Não existem Dados"
                  :footer-props="{
                    'items-per-page-text': 'Mostrar',
                    'items-per-page-options': [10, 25, 50],
                    'items-per-page-all-text': 'Todos',
                  }"
                  dense
                  show-expand
                  :items-per-page=10
                  :headers="headers" 
                  :items="getFilteredItems"
                  item-key="cod"
                  class="elevation-1"
                  color="#009263"
                >
                <template v-slot:[`footer.page-text`]="props">
                  {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <Preview :pergunta="item"/>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="adicionar(item.cod)" v-bind="attrs" v-on="on" large class="white--text mb-5 ml-8" color="#009263">
                          <v-icon >
                          mdi-plus
                          </v-icon>
                          </v-btn>
                      </template>
                      <span>Adicionar</span>
                    </v-tooltip>
                  </td>
                </template>
                <template v-slot:[`item.questao`]="{ item }">
                    <span v-html="item.questao"></span>
                </template>
                <template v-slot:[`item.tipo`]="{ item }">
                    {{getTipo(item.tipo)}}
                </template>
                <template v-slot:[`item.idexame`]="{ item }">
                    {{getExame(item.idexame)}}
                </template>
                <template v-slot:[`item.tema`]="{ item }">
                    {{ getTema(item.tema) }}
                </template>
                <template v-slot:[`item.subtema`]="{ item }">
                    {{ getSubtema(item.tema, item.subtema) }}
                </template>
              </v-data-table>
              <v-row class="mt-2 mr-2">
                    <v-col style="margin-left: 32px" cols="12" sm="8" md="6" lg="4" xl="4">
                      <v-btn
                        large
                        class="white--text"
                        color="#009263"
                        @click="confirm"
                        :disabled="allChipsEmpty"
                        >Guardar</v-btn
                      >
                    </v-col>
              </v-row>
            </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
  </div>
</template>

<script>
const axios = require("axios");
const host = require("@/config/hosts").hostAPI;
import Preview from "../components/Preview.vue";
//const currHostImages = require("@/config/hosts").currHostImages;
//const hostImages = require("@/config/hosts").hostImagesSelector[currHostImages];
const Swal = require("sweetalert2");


export default {
  components:{
    Preview
  },
  props: {

  },
  data() {
    return {
        loading: true,
        show:false,
        results: [],

        categorias: ["Nível 1", "Nível 2", "Nível 3", "Nível 4"], // 0, 1, 2, 3
        categoria: 0,
        questoesCategorias: {},

        chips: { '0': [], '1': [], '2': [], '3': [], '4': [] },

      exames: [],
      temas: [],
      catalogoTemas: {},
      temasFiltro: [],
      subtemasFiltro: [],
      tipos: ['Escolha Múltipla', 'Resposta Aberta', 'Verdadeiro ou Falso', 'Simetria'],
      subtipos: ["1 Resposta Correta", "2 Respostas Corretas", "Várias Respostas Corretas", "Fração Redutível", "Fração Irredutível", "Utilizar Transferidor"],
      subtipoSel: '',
      headers: [
        { text: "Código", value: "cod", class: "subtitle-1", width:'7%' },
        { text: "Tipo", value: "tipo", class: "subtitle-1", width:'10%'},
        { text: "Questão", value: "questao", class: "subtitle-1" ,width:'20%'},
        { text: "Exame", value: "idexame", class: "subtitle-1" ,width:'20%', align:'center'},
        { text: "Tema", value: "tema", class: "subtitle-1" ,width:'10%'},
        { text: "Subtema", value: "subtema", class: "subtitle-1" ,width:'15%'},
        { text: "Nível", value: "niveldificuldade", class: "subtitle-1" },
        { text: "Ano", value: "ano", class: "subtitle-1"}
      ],
      exameValue: '',
      temaSelected: '',
      subtemaSelected: '',
      anoSel: '',
      nivelSel: '',
      respostaSel: '',
      questao: '',
      codigo: '',
      tipoSel: '',
      anoRules: [(v) => v && v > 0 && v < 10],
      nivelRules: [(v) => v && v > 0 && v < 5],
      auxiliar: 0
    };
  },
  methods: {

    async getQuestoes(){
      const data = await axios.get(host + 'perguntas?_limit=-1')
      this.results = data.data
      this.loading = false
    },

    async getQuestoesCategorias(){
      const data = await axios.get(host + 'questoes_mensais/mes')
/*       this.questoesCategorias = { // GET mes e ano atual
        '0': [{cod: '13126'}, {cod:'13097'}, {cod:'204041'}],
        '1': [],
        '2': [],
        '3': [{cod:'1014024'}],
        '4': [{cod:'510022'}, {cod:'417089'}]
      } */
      this.questoesCategorias = data.data

/*       for(let i = 0; i < 5; i++){
        this.chips[i] = this.questoesCategorias[i].map( pergunta => pergunta.cod )
      } */
      Object.entries(this.questoesCategorias).forEach( array => {
        this.chips[array[0]] = array[1]
      })

      //console.log("QUESTOES CATEGORIAS", this.questoesCategorias)
      //console.log("CHIPS", this.chips)
    },

    changeCategoria(){
      //console.log("change categoria", this.categoria)
    },

    removeChip(i){
      this.chips[this.categoria].splice(i, 1)
    },

    adicionar(cod){
      let index =  this.chips[this.categoria].indexOf(cod);
      if (index === -1){
        this.chips[this.categoria].push(cod)
      } 
      //console.log("add chip")
    },

    confirm(){
      //this.chips[this.categoria].push("ola")

      // REMOVER DAS QUESTOES ESCONDIDAS AQUELAS QUE JA NAO ESTAO NOS CHIPS !!!
      // ADICIONAR AS QUESTOES ESCONDIDAS AS QUE ESTAO NOS CHIPS
      // REMOVER DAS QUESTOES ESCONDIDAS A EDIÇAO ANTERIOR (SÓ PERMITIR INSERIR DE MESES ACIMA, PRA TRÁS É HISTÓRICO)
      try {
        Swal.fire({
          title: `Tem a certeza que pretende alterar as questões mensais para o mês atual?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#009263",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Loading modal
            Swal.fire({
              title: "A efetuar a operação...",
              showConfirmButton: false,
              allowOutsideClick: false,
              willOpen: () => {
                Swal.showLoading();
              },
            });

            axios.post(host + 'questoes_mensais', {categorias: this.chips})
              .then(() => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: "Questões do mês atual alteradas com sucesso!",
                  confirmButtonColor: "#009263",
                })
                .then(result => {
                  if(result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
                .catch(e => {throw new Error(e)}) 
              })
              .catch(error => {
                Swal.close();
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message,
                  confirmButtonColor: "#009263",
                })
              })
            }
        });
      } catch (err) {
        const error = new Error(err.message || "Não foi possível aceitar a submissão.");
        throw error;
      }
    },

    getTema(tema){
      return this.temas[tema]?.tema 
    },

    getSubtema(tema,subtema){
      return this.temas[tema]?.subtemas[subtema]
    },

    getTipo(tipo){
      return tipo === 1 
      ? 'Resposta Aberta'
      : tipo === 3 
      ? 'Verdadeiro ou Falso'
      : tipo === 7 || tipo === 33
      ? 'Simetria'
      : 'Escolha Múltipla'
    },

    changeSubtipo(){
      if (this.tipoSel !== this.tipos[1]){
        this.auxiliar = 0
      }
      else{
        switch(this.subtipoSel){
          case this.subtipos[0]:
            this.auxiliar = 1
            break
          case this.subtipos[1]:
            this.auxiliar = 1000
            break
          case this.subtipos[2]:
            this.auxiliar = 0
            break
          case this.subtipos[3]:
            this.auxiliar = 22
            break
          case this.subtipos[4]:
            this.auxiliar = 2
            break
          case this.subtipos[5]:
            this.auxiliar = 10
            break
          default:
            this.auxiliar = 0
            break
        }
      }
      //console.log("change subtipo, auxiliar: ", this.auxiliar)
    },
    changeSubtemas(){
      this.subtemaSelected = ''
      let tema = this.catalogoTemas[this.temaSelected]
      if(this.temaSelected != null) this.subtemasFiltro = Object.entries(this.temas[tema].subtemas).map(e => e[1])
      else this.subtemasFiltro = []
    },

    wipeSubtema(){
      this.temaSelected = ''
      this.subtemaSelected = ''
    },

    changeTipo(){
      this.subtipoSel = '' 
    },

    getSubtemaNr(tema){
      let subtemas = this.temas[tema].subtemas 
      if(this.temaSelected != '' && this.subtemaSelected != ''&& this.temaSelected != null && this.subtemaSelected != null) {
          for (var [key, value] of Object.entries(subtemas)) {
              if(this.subtemaSelected == value) return key
          }
      }
      return ''
    },

    async getTemas(){
      const data = await axios.get(host + 'temas/agrupar?_limit=-1')
      this.temas = data.data
      this.temasFiltro = Object.entries(this.temas).map(entry => entry[1].tema)
      this.temasFiltro.sort((a, b) => a.localeCompare(b))
      this.catalogoTemas = Object.fromEntries(Object.entries(this.temas).map(entry => [entry[1].tema, entry[0]]))
    },

    async getExames(){
      const data = await axios.get(host + 'perguntas/exames')
      this.exames.push('Nenhum')
      this.exames.push( ...data.data )
    },

    getExame(idexame){
      return idexame === '' ? '-' : idexame 
    },

    isInResposta(questao){
      let res = false
      for(let i=1 ; i<=6 ; i++){
        if(questao[`resposta${i}`].toString().toLowerCase().includes(this.respostaSel.toLowerCase())) {
            res = true
            break;
        }
      }
      return res
    },  
  },
  computed: {
    getChips(){
      return this.chips[this.categoria]
    },

    isEmptyChips(){
      return this.chips[this.categoria].length === 0
    },

    getFilteredItems() {
      let filtered = [...this.results]
              .filter(questao => {
                return questao.cod.toLowerCase().includes(this.codigo.toLowerCase()) &&
                  questao.questao.toLowerCase().includes(this.questao.toLowerCase()) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null ? questao.tema.toLowerCase() == this.catalogoTemas[this.temaSelected] : questao.tema.toLowerCase().includes('')) &&
                  (this.catalogoTemas[this.temaSelected] && this.temaSelected != '' && this.temaSelected != null && this.subtemaSelected != '' && this.subtemaSelected != null ? questao.subtema.toLowerCase() == this.getSubtemaNr(questao.tema) : questao.subtema.toLowerCase().includes('')) &&
                  this.getTipo(questao.tipo).toLowerCase().includes(this.tipoSel.toLowerCase()) &&
                  (this.exameValue==='Nenhum' ?  questao.idexame.toLowerCase() === '' :  questao.idexame.toLowerCase().includes(this.exameValue.toLowerCase())) && 
                  questao.ano.toString().toLowerCase().includes(this.anoSel.toString().toLowerCase()) &&
                  questao.niveldificuldade.toString().toLowerCase().includes(this.nivelSel.toString().toLowerCase()) &&
                  (this.tipoSel === this.tipos[1] && this.subtipoSel !== '' && this.subtipoSel !== null ? questao.auxiliar === this.auxiliar : true) &&
                  this.isInResposta(questao)
              })
      return filtered
    },

    allChipsEmpty(){
      let flag = true
      Object.entries(this.chips).forEach(array => {
        if (array[1].length > 0){
          flag = false
        }
      })
      //console.log(this.chips,flag)
      return flag
    }

  },
  mounted() {
    this.getTemas()
    this.getExames()
    this.getQuestoes()
    this.getQuestoesCategorias()
  }
};
</script>

<style scoped>
table th:not(:first-child) + th { border-left:1px solid #dddddd; }
table td:not(:first-child) + td { border-left:1px solid #dddddd; }
</style>
